<template>
  <div class="container position-relative">
    <div class="row">
      <div class="col-12 header-logo-div m-0">
        <a style="
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;" href="javascript:void(0)" @click="clickBack">
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background: new 0 0 100 125"
            xml:space="preserve"
            height="40"
            width="30"
            fill="#000000"
          >
            <g id="Layer_2"></g>
            <g id="Layer_1">
              <path
                d="M12.1,47.1C12.1,47.1,12.1,47.1,12.1,47.1l29.5-29.5c1.6-1.6,4.2-1.6,5.8,0c1.6,1.6,1.6,4.2,0,5.8L24.9,45.9h61.8
		c2.3,0,4.1,1.8,4.1,4.1s-1.8,4.1-4.1,4.1H24.9l22.5,22.5c1.6,1.6,1.6,4.2,0,5.8c-0.8,0.8-1.8,1.2-2.9,1.2s-2.1-0.4-2.9-1.2
		L12.1,52.9c0,0,0,0,0,0L9.2,50L12.1,47.1z"
              />
            </g>
          </svg>
        </a>
        <img v-if="consumer_type == 'lite'" class="header-logo" src="../../../assets/images/canpay-logo-lite.png" />
        <img v-else class="header-logo" src="../../../assets/images/canpay-logo-new.png" />
      </div>
      <HeaderWheel class="rw-header mr-2"/>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
import HeaderWheel from "../../RewardWheel/HeaderWheel.vue";

export default {
  name: "BankLinkingHeader",
  components: {
    DrawerLayout,
    HeaderWheel
  },

  data: () => ({
    open: true,
    showmenuicon: true,
    consumer_type: localStorage.getItem("consumer_login_response")
        ? JSON.parse(localStorage.getItem("consumer_login_response")).consumer_type
        : null,
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },
  methods: {
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    },
    clickBack() {
      const previousRouteName = this.$route.meta.previousRouteName;
      if(previousRouteName != '' && previousRouteName != 'success'){
        this.$router.go(-1);
      } else {
        if (localStorage.getItem('consumer_token') != null) {
          this.$router.push("/pay");
        } else {
          this.$router.push("/login");
        }
      }
    }
  }
};
</script>

<style>
.rw-header{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
</style>
