<template>
  <div>
    <!-- Sub header Text row -->
    <div v-if="!checkAddress">
      <div class="row text-msg-label">
        <div class="col-2"></div>
        <div class="col-8">
          <span class="setup-label">
            Tell us a bit more
            <br />about yourself
          </span>
        </div>
        <div class="col-2"></div>
      </div>
      <!-- email row -->
      <div class="row email-row-space dob-row">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon more-details-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 32 40"
                style="enable-background: new 0 0 32 40"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <g>
                  <g>
                    <path
                      d="M30,28H2c-0.6,0-1-0.4-1-1V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v22C31,27.6,30.6,28,30,28z M3,26h26V6H3V26z"
                    />
                  </g>
                  <g>
                    <path
                      d="M16,20.4c-0.2,0-0.4-0.1-0.5-0.2l-14-9C1.2,11.1,1,10.7,1,10.4V5c0-0.6,0.4-1,1-1h28c0.6,0,1,0.4,1,1v5.4
			c0,0.3-0.2,0.7-0.5,0.8l-14,9C16.4,20.4,16.2,20.4,16,20.4z M3,9.9l13,8.4l13-8.4V6H3V9.9z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <input
              v-model="email"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              text-lowercase
              auto-fill
            "
              placeholder="Email"
              inputmode="email"
              :readonly="is_lite_to_normal_registration_started"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Email')"
            />
          </div>
        </div>
      </div>
      <!-- date of birth text row -->
      <div class="row dob-row">
        <div class="col-12">
          <label class="dob-style">Date Of Birth</label>
        </div>
      </div>
      <!-- date of birth row -->
      <div class="row dob-row">
        <!-- element month -->
        <div class="col-6">
          <div class="btn-group">
            <button
              id="btn-month"
              class="btn-reg-dropdown-style"
              aria-haspopup="false"
              aria-expanded="false"
              @click="openMonthModal"
            >
              <div class="row">
                <div class="col-6 align-self-center">
                  <span class="text-month font-email float-left">{{
                    dob_month
                  }}</span>
                </div>
                <div class="col-6 pr-4">
                  <span class="float-right">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      width="10"
                      height="10"
                      viewBox="0 0 56 48.5"
                      style="enable-background: new 0 0 56 48.5"
                      xml:space="preserve"
                    >
                      <path id="Polygon_1" d="M28,48.5L0,0l56,0L28,48.5z" />
                    </svg>
                  </span>
                </div>
              </div>
            </button>
          </div>
        </div>
        <!-- element day -->
        <div class="col-3" style="padding-left: unset">
          <input
            v-model="dob_day"
            class="
            form-control
            btn-reg-dropdown-style
            day-placeholder
            text-center
          "
            placeholder="Day"
            v-on:keyup="dobValidation($event)"
            @focus="removeattribute($event)"
            v-on:blur="addZero($event, 'Day')"
            type="number"
            pattern="[0-9]*"
            maxlength="2"
          />
        </div>
        <!-- element year -->
        <div class="col-3" style="padding-left: unset">
          <input
            v-model="dob_year"
            type="number"
            pattern="[0-9]*"
            class="
            form-control
            btn-reg-dropdown-style
            day-placeholder
            text-center
          "
            placeholder="Year"
            v-on:keyup="isNumber($event)"
            v-on:blur="checkValidYear($event, 'Year')"
            @focus="removeattribute($event)"
            maxlength="4"
          />
        </div>
      </div>
      <!-- address row -->
      <div class="row dob-row">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon more-details-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 307 495"
                style="enable-background: new 0 0 307 495"
                xml:space="preserve"
                height="20"
                width="20"
              >
                <g>
                  <path
                    d="M153,0c85,0,154,69,154,153c0,88-90,191-154,243C90,344,0,241,0,153C0,69,69,0,153,0z M153,27C84,27,27,84,27,153
		c0,71,76,164,126,208c51-44,127-137,127-208C280,84,223,27,153,27z"
                  />
                  <path
                    d="M153,88c36,0,66,30,66,65c0,36-30,66-66,66c-35,0-65-30-65-66C88,118,118,88,153,88z M153,115c-21,0-38,17-38,38
		c0,22,17,38,38,38c22,0,38-16,38-38C191,132,175,115,153,115z"
                  />
                </g>
              </svg>
            </span>
            
            <gmap-autocomplete
            :value="fulladdress"
            placeholder="Home Address"
            @place_changed="setPlace"
            :id="add_id"
            class="autocomplete form-control more-details-input font-email auto-fill">
          </gmap-autocomplete>
          </div>
        </div>
      </div>

      <!-- apt number row -->
      <div class="row dob-row">
        <div class="col-12">
          <div class="form-group">
            <span class="form-control-icon">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                height="30"
                width="30"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
              >
                <title>Straight_Line</title>
                <path
                  d="M25,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C27.5,86.4,26.4,87.5,25,87.5z"
                />
                <path
                  d="M75,17.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,17.5,75,17.5z"
                />
                <path
                  d="M75,87.5c-1.4,0-2.5-1.1-2.5-2.5V15c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v70C77.5,86.4,76.4,87.5,75,87.5z"
                />
                <path
                  d="M75,87.5H25c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h50c1.4,0,2.5,1.1,2.5,2.5S76.4,87.5,75,87.5z"
                />
                <path
                  d="M35,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C37.5,86.4,36.4,87.5,35,87.5z"
                />
                <path
                  d="M45,67.5H35c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C47.5,66.4,46.4,67.5,45,67.5z"
                />
                <path
                  d="M45,87.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v20C47.5,86.4,46.4,87.5,45,87.5z"
                />
                <path
                  d="M45,27.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,27.5,45,27.5z"
                />
                <path
                  d="M45,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,36.4,46.4,37.5,45,37.5z"
                />
                <path
                  d="M45,37.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,37.5,45,37.5z"
                />
                <path
                  d="M35,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,36.4,36.4,37.5,35,37.5z"
                />
                <path
                  d="M65,27.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,27.5,65,27.5z"
                />
                <path
                  d="M65,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,36.4,66.4,37.5,65,37.5z"
                />
                <path
                  d="M65,37.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,37.5,65,37.5z"
                />
                <path
                  d="M55,37.5c-1.4,0-2.5-1.1-2.5-2.5V25c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,36.4,56.4,37.5,55,37.5z"
                />
                <path
                  d="M45,47.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,47.5,45,47.5z"
                />
                <path
                  d="M45,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C47.5,56.4,46.4,57.5,45,57.5z"
                />
                <path
                  d="M45,57.5H35c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S46.4,57.5,45,57.5z"
                />
                <path
                  d="M35,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C37.5,56.4,36.4,57.5,35,57.5z"
                />
                <path
                  d="M65,47.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,47.5,65,47.5z"
                />
                <path
                  d="M65,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,56.4,66.4,57.5,65,57.5z"
                />
                <path
                  d="M65,57.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,57.5,65,57.5z"
                />
                <path
                  d="M55,57.5c-1.4,0-2.5-1.1-2.5-2.5V45c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,56.4,56.4,57.5,55,57.5z"
                />
                <path
                  d="M65,67.5H55c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5C67.5,66.4,66.4,67.5,65,67.5z"
                />
                <path
                  d="M65,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5v10C67.5,76.4,66.4,77.5,65,77.5z"
                />
                <path
                  d="M65,77.5H55c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5h10c1.4,0,2.5,1.1,2.5,2.5S66.4,77.5,65,77.5z"
                />
                <path
                  d="M55,77.5c-1.4,0-2.5-1.1-2.5-2.5V65c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v10C57.5,76.4,56.4,77.5,55,77.5z"
                />
              </svg>
            </span>
            <input
              @keyup.enter="clickNext()"
              @focus="setAutocomplete($event)"
              v-model="apt_number"
              autocomplete="apt"
              placeholder="Apartment or Unit #"
              class="form-control"
            />
          </div>
        </div>
      </div>
      <div class="row dob-row address-btn ">
        <div class="col-12">
          <div class="form-group">
            <button type="button" class="btn-verify" @click="clickNext">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="checkAddress">
      <div class="row text-msg-label">
        <div class="col-2"></div>
        <div class="col-8">
          <span class="setup-label">
            Enter Your Address
          </span>
        </div>
        <div class="col-2"></div>
      </div>
      <div class="row email-row-space dob-row mt-5">
        <div class="col-12">
          <div class="form-group">
            <input
              v-model="address"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              auto-fill
            "
              placeholder="Home Address"
              inputmode="Home Address"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Home Address')"
            />
          </div>
        </div>
      </div>

      <div class="row email-row-space dob-row">
        <div class="col-6">
          <div class="form-group">
            <input
              v-model="apt_number"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              auto-fill
            "
              placeholder="Apt or Unit #"
              inputmode="Apt or Unit #"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Apt or Unit #')"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <input
              v-model="city"
              v-on:keypress="isLetter($event)"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              auto-fill
            "
              placeholder="City"
              inputmode="City"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'City')"
            />
          </div>
        </div>
      </div>

      <div class="row email-row-space dob-row">
        <div class="col-6">
          <div class="form-group">
            <input
              v-model="state"
              v-on:keypress="isLetter($event)"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              text-uppercase
              auto-fill
            "
              placeholder="State"
              inputmode="State"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'State')"
              maxlength="2"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <input
              v-model="zip"
              v-on:keypress="isNumber($event)"
              class="
              form-control
              more-details-input
              input-box-padding
              font-email
              auto-fill
            "
              placeholder="Zip"
              inputmode="Zip"
              @focus="removeattribute($event)"
              @blur="setattribute($event, 'Zip')"
              maxlength="5"
            />
          </div>
        </div>
      </div>
      <div class="row dob-row address-btn mt-5">
        <div class="col-12">
          <div class="form-group">
            <button
              type="button"
              class="btn-verify"
              @click="submitManualAddress"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--Next Button row-->

    <!-- Month dropdown modal -->
    <div>
      <b-modal
        ref="month-modal"
        hide-footer
        hide-header
        size="sm"
        centered
        variant="primary"
        id="month-modal"
      >
        <div>
          <center>
            <div
              v-for="month in months"
              class="row suffix-option"
              @click="setMonth(month)"
              :key="month.key"
            >
              {{ month.title }}
            </div>
          </center>
        </div>
      </b-modal>
    </div>
    <!-- End of Month dropdown modal -->
    <!-- Validation modal -->
    <b-modal
      ref="validation-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hidevalidationModal"
            >
              <label class="purchasepower-modal-ok-label">OK</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- input city  modal -->
    <div>
      <b-modal
        ref="input-field-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        id="input-field-modal"
        centered
      >
        <div class="color">
          <div class="purchaserpower-modal-text">
            <form
              ref="form"
              @submit.stop.prevent="save"
              class="needs-validation"
            >
              <div
                class="row"
                v-for="(fieldName, index) in googleNotReadAddress"
                :key="index"
              >
                <div class="col-4 mt-2 ">
                  <label class="purchasepower-def-label">
                    {{ fieldName }}
                  </label>
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'state'">
                  <input
                    type="text"
                    v-model="state"
                    autocomplete="off"
                    class="form-control border border-dark   text-uppercase"
                    v-bind:style="{ 'border-color': borderColor }"
                    maxlength="2"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'city'">
                  <input
                    v-on:keypress="isLetter($event)"
                    type="text"
                    v-model="city"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColor }"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'zip'">
                  <input
                    v-on:keypress="isNumber($event)"
                    type="text"
                    v-model="zip"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColor }"
                    maxlength="5"
                  />
                </div>
                <div class="col-8 mt-2" v-if="fieldName == 'address'">
                  <input
                    type="text"
                    v-model="address"
                    autocomplete="off"
                    class="form-control border border-dark"
                    v-bind:style="{ 'border-color': borderColor }"
                  />
                </div>
              </div>
            </form>

            <br />
            <br />
            <div class="text-center">
              <button
                type="button"
                class="mx-auto col-10 offset-1 btn-black"
                style="height: 60px"
                @click="hideInputCityModal"
              >
                <label class="purchasepower-modal-ok-label">OK</label>
              </button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <!-- End of input city modal -->
    <b-modal
      ref="validation-address-modal"
      hide-footer
      v-b-modal.modal-center
      modal-backdrop
      hide-header
      id="validation-address-modal"
      centered
    >
      <div class="color">
        <div class="purchaserpower-modal-text">
          <div class="row" style="margin-bottom: 5px">
            <div class="col-12 text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="120"
                height="120"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                fill="#149240"
              >
                <path
                  d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
                />
              </svg>
            </div>
          </div>
          <div class="d-block text-center">
            <label class="purchasepower-def-label">
              {{ error_message }}
            </label>
          </div>
          <br />
          <br />
          <div class="text-center">
            <button
              type="button"
              class="mx-auto col-10 offset-1 btn-black"
              style="height: 60px"
              @click="hideValidationAddressModal"
            >
              <label class="purchasepower-modal-ok-label">Enter Manually</label>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <div></div>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import constants from "../Common/constant.js";
import moment from "moment";
export default {
  name: "EnterMoreDetails",
  components: {
    VueGoogleAutocomplete,
  },
  data: function () {
    return {
      add_id: String(new Date().getTime()),
      constants: constants,
      show: constants.enter_more_details,
      error_message: "",
      endDate: new Date(),
      formatted_date: "",
      address: "",
      state: "",
      zip: "",
      city: "",
      day: "",
      apt_number: "",
      dob_year: "",
      dob_day: "",
      month_number: "",
      dob_month: "Month",
      fulladdress: "",
      email: "",
      googleNotReadAddress: [],
      checkAddressNotCome: false,
      checkAddress: false,
      usaState: [  
        "AA","AE","AK","AL","AP","AR","AS",
        "AZ","CA","CO","CT","DC","DE","FL","FM","GA",
        "GU","HI","IA","ID","IL","IN","KS",
        "KY","LA","MA","MD","ME","MH","MI","MN","MO","MP",
        "MS","MT","NC","ND","NE","NH","NJ","NM","NV","NY",
        "OH","OK","OR","PA","PR","PW","RI","SC","SD","TN",
        "TX","UT","VA","VI", "VT","WA","WI", "WV", "WY"
      ],
      months: [
        { title: "Month", key: "" },
        { title: "January", key: 1 },
        { title: "February", key: 2 },
        { title: "March", key: 3 },
        { title: "April", key: 4 },
        { title: "May", key: 5 },
        { title: "June", key: 6 },
        { title: "July", key: 7 },
        { title: "August", key: 8 },
        { title: "September", key: 9 },
        { title: "October", key: 10 },
        { title: "November", key: 11 },
        { title: "December", key: 12 },
      ],
      borderColor: "",
    };
  },
  props: [
    "aptNumberProp",
    "liteToNormalRegistrationStartedProp",
    "dobYearProp",
    "dobDayProp",
    "monthNumberProp",
    "dobMonthProp",
    "emailProp",
    "fulladdressProp",
    "addressProp",
    "stateProp",
    "zipProp",
    "cityProp",
  ],
  mounted: function () {
    this.dob_month = this.dobMonthProp != "" ? this.dobMonthProp : "Month";
    this.apt_number = this.aptNumberProp;
    this.is_lite_to_normal_registration_started = this.liteToNormalRegistrationStartedProp;
    this.dob_year = this.dobYearProp;
    this.dob_day = this.dobDayProp;
    this.month_number = this.monthNumberProp;
    this.email = this.emailProp;
    this.fulladdress = this.fulladdressProp;
    this.address = this.addressProp;
    this.state = this.stateProp;
    this.city = this.cityProp;
    this.zip = this.zipProp;
    this.formatted_date = moment(String(new Date())).format("YYYY-MM-DD");
    let self = this;
    this.$root.$on("showScreen", function (data) {
      self.show = data; //setting the flag for next screen
    });
  },
  methods: {
    setAutocomplete(el) {
      el.target.setAttribute("autocomplete", "apt");
    },
    removeattribute(el) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).removeAttribute("placeholder");
      } else {
        el.target.removeAttribute("placeholder");
      }
    },
    setattribute(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hidevalidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
    },
    showInputCityModal() {
      this.$refs["input-field-modal"].show();
    },
    hideInputCityModal() {
      let checkValue = [];

      if (this.googleNotReadAddress.indexOf("zip") !== -1) {
        if (this.zip.trim() != "") {
          this.zip = this.zip.trim();
          this.borderColor = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColor = "#e14343 !important";
        }
      }

      if (this.googleNotReadAddress.indexOf("address") !== -1) {
        if (this.address.trim() != "") {
          this.address = this.address.trim();
          this.borderColor = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColor = "#e14343 !important";
        }
      }

      if (this.googleNotReadAddress.indexOf("state") !== -1) {
        if (this.state.trim() != "") {
          this.state = this.state.toUpperCase();
          this.borderColor = "";
          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColor = "#e14343 !important";
        }
      }

      if (this.googleNotReadAddress.indexOf("city") !== -1) {
        if (this.city.trim() != "") {
          this.city = this.city.trim();
          this.borderColor = "";

          checkValue.push(true);
        } else {
          checkValue.push(false);
          this.borderColor = "#e14343 !important";
        }
      }
      if(this.address != ''){
        this.fulladdress = this.address;
      }
      if(this.city != ''){
        this.fulladdress = this.fulladdress + ", " + this.city;
      }
      if(this.state != ''){
        this.fulladdress = this.fulladdress + ", " +this.state;
      }
      if(this.zip != ''){
        this.fulladdress = this.fulladdress + " " +this.zip;
      }
      if(this.country != ''){
        this.fulladdress = this.fulladdress + ", " +this.country;
      }
      if (checkValue.indexOf(false) === -1) {
        checkValue = [];
        this.$refs["input-field-modal"].hide();
      } else {
        checkValue = [];
      }
    },
    //this function validated dob day just like a datepicker
    dobValidation(evt) {
      if (this.isNumber(evt)) {
        // case 1: if month is february then maximum 29 should be day limit (Leap year vallidation is handled in a different function)
        // case 2: months with 31 days
        //case 3: months with 30 days
        var limit = this.month_number % 2 == 0 ? 30 : 31;
        if (
          this.month_number == 8 ||
          this.month_number == 10 ||
          this.month_number == 12
        ) {
          limit = 31;
        }
        limit = this.month_number == 2 ? 29 : limit;
        if (this.dob_day > limit) {
          this.dob_day = this.day;
          if (this.dob_day.length < 2) {
            this.dob_day = 0 + this.dob_day;
          }
        }
        this.day = this.dob_day;
      }
    },
    //checks if proper year format is entered or not
    checkValidYear(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
      if (this.dob_year.length < 4 && this.dob_year.length != 0) {
        this.showValidationModal("Please enter a four digit year.");
      }
    },
    //adds zero to the day
    addZero(el, placeholder) {
      if (typeof el.target === "undefined") {
        document.getElementById(el).setAttribute("placeholder", placeholder);
      } else {
        el.target.setAttribute("placeholder", placeholder);
      }
      if (this.dob_day.length < 2 && this.dob_day.length != 0) {
        this.dob_day = 0 + this.dob_day;
      }
    },
    //sets the value of month variable according to the value selected in the month option modal
    setMonth(month) {
      this.dob_month = month.title;
      this.month_number = month.key;
      this.hideMonthModal();
    },
    //opens month option modal
    openMonthModal() {
      this.$refs["month-modal"].show();
      setTimeout(function () {
        var element = document.getElementsByClassName("modal-sm")[0];
        element.classList.add("month-modal-sm");
      }, 10);
    },
    //closes month option modal
    hideMonthModal() {
      this.$refs["month-modal"].hide();
    },
    //checks if the value entered is number or not
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // check the value enter is charecter
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[a-zA-Z\s]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    //gets the address from google api
    setPlace(place) {
        var self = this;
        self.fulladdress = place.formatted_address;
        self.setLocation(place.address_components);
    },

    setLocation(addressArray){
        var self = this;
        var streetAddress = '';
        var streetNumber = '';
        var route = '';
        self.address = '';
        self.country = '';
        self.state = '';
        self.city = '';
        self.zip = '';
        for (var i = 0; i < addressArray.length; i++) {
            // street number
            if(addressArray[i]['types'][0] == 'street_number'){
                streetNumber = addressArray[i].long_name;
            }
            // route
            if(addressArray[i]['types'][0] == 'route'){
                route = addressArray[i].long_name;
            }
            // street address
            if(streetNumber !== '' || route!==''){
                streetAddress = streetNumber+" "+route;
                self.address = streetAddress;
            }
            // country
            if(addressArray[i]['types'][0] == 'country'){
                self.country = addressArray[i].short_name;
            }
            // state
            if(addressArray[i]['types'][0] == 'administrative_area_level_1'){
                self.state = addressArray[i].short_name;
            }
            // city
            if(addressArray[i]['types'][0] == 'locality'){
                self.city = addressArray[i].long_name;
            }
            // zip
            if(addressArray[i]['types'][0] == 'postal_code'){
                self.zip = addressArray[i].long_name;
            }


        }
        self.googleNotReadAddress = [];
        if(self.address == ""){
          self.googleNotReadAddress.push("address");
        }
        if(self.state == ""){
          self.googleNotReadAddress.push("state");
        }
        if(self.city == ""){
          self.googleNotReadAddress.push("city");
        }
        if(self.zip == ""){
          self.googleNotReadAddress.push("zip");
        }
        if (self.address == "" || self.city == "" || self.state == "" || self.zip == "" || self.country == "") { 
          self.showInputCityModal();
        }
    },
    

    //this function emits the data to parent component and the perfoms the next action
    clickNext() {
      var self = this;
      if (
        this.month_number == "" ||
        this.dob_day == "" ||
        this.dob_year == ""
      ) {
        self.showValidationModal("Please enter your date of birth");
        return false;
      }
      if (this.address == null || this.address.length == 0) {
        self.checkAddress = true;
        self.showValidationAddressModel();
        return false;
      }
      if (this.email == null || this.email.length == 0) {
        self.showValidationModal("Please enter your email.");
        return false;
      }
      //check for valid date of birth
      /**
       * if month selected if february and day selected 29 needs to check if the year is a leap year
       */
      if (
        this.month_number == 2 &&
        this.dob_day == 29 &&
        this.dob_year % 4 != 0
      ) {
        self.showValidationModal("Date of birth must be valid.");
        return false;
      }
      var formatted_date =
        this.month_number + "-" + this.dob_day + "-" + this.dob_year;
      var date = moment(formatted_date, "MM-DD-YYYY");
      if (!date.isValid()) {
        self.showValidationModal("Date of birth must be valid.");
        return false;
      }
      //email validation
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(String(this.email).toLowerCase())) {
        self.showValidationModal("Please enter a valid email address.");
        return false;
      }
      //emits the data from this page to the parent page i.e the EnterNamePhone page
      this.$root.$emit("add_address", [
        [this.month_number, this.dob_day, this.dob_year],
        [this.address, this.state, this.city, this.zip],
        this.email,
        this.apt_number,
        this.dob_month,
        this.fulladdress,
      ]);
    },

//if google autocomplete address not working manually modal show

    showValidationAddressModel() {
      this.error_message = "No Matching Address Found";
      this.apt_number = null;
      this.$refs["validation-address-modal"].show();
    },

    hideValidationAddressModal() {
      this.$refs["validation-address-modal"].hide();
    },

    submitManualAddress() {
      var self = this;
      if (self.address == null || self.address.length == 0) {
        self.showValidationModal("Please enter your Home Address");
        return false;
      }
      if (self.apt_number == null || self.apt_number.length == 0) {
        self.showValidationModal("Please enter your apt number");
        return false;
      }
      if (self.city.trim() == "") {
        self.showValidationModal("Please enter your city");
        return false;
      }
      if (self.state.trim() == "") {
        self.showValidationModal("Please enter your state");
        return false;
      }
      if (self.zip.trim() == "") {
        self.showValidationModal("Please enter your zip");
        return false;
      }
      self.address = self.address;
      self.apt_number = self.apt_number;
      self.city = self.city.trim();
      self.zip = self.zip;
      self.state = self.state.toUpperCase();
      console.log( self.state);
      if(!(self.usaState.includes(self.state) ))
      {
        self.showValidationModal("State must be one of: AA, AE, AK, AL, AP, AR, AS, AZ, CA, CO, CT, DC, DE, FL, FM, GA, GU, HI, IA, ID, IL, IN, KS, KY, LA, MA, MD, ME, MH, MI, MN, MO, MP, MS, MT, NC, ND, NE, NH, NJ, NM, NV, NY, OH, OK, OR, PA, PR, PW, RI, SC, SD, TN, TX, UT, VA, VI, VT, WA, WI, WV, WY.");
        return false;
      }

      this.$root.$emit("add_address", [
        [this.month_number, this.dob_day, this.dob_year],
        [this.address, this.state, this.city, this.zip],
        this.email,
        this.apt_number,
        this.dob_month,
        this.fulladdress
      ]);
    }
  }
};

</script>
<style>
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 12px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  text-align: left !important;
}
#month-modal___BV_modal_body_ {
  background-color: #ffffff;
  margin: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}
#month-modal___BV_modal_content_ {
  background-color: #ffffff;
}
.month-modal-sm {
  float: left !important;
}
#input-field-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}

#validation-address-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
@media only screen and (width: 600px) and (height: 960px) {
  .month-modal-sm {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .month-modal-sm {
    margin-left: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .month-modal-sm {
    margin-left: 120px;
  }
}
@media (min-width: 1281px) {
  .month-modal-sm {
    margin-left: 293px;
    margin-top: 14px;
  }
}
</style>