<template>
  <div>
    <!-- Warning page section-->
    <div v-if=" screen === 'warning'">
      <div class="row warning-icon-space">
        <div class="col-12">
          <span class="text-center">
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              width="120"
              height="120"
              viewBox="0 0 100 125"
              style="enable-background:new 0 0 100 125;"
              xml:space="preserve"
              fill="#ffffff"
            >
              <path
                d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
	h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
	 M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
	c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
	c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
              />
            </svg>
          </span>
        </div>
      </div>
      <!-- para 1 -->
      <div class="row btn-row-space">
        <div class="col-12">
          <span class="manual-text">
            Thank you for registering with CanPay,
            <br />it's very important that we are able to
            <br />validate every CanPay member.
          </span>
        </div>
      </div>
      <!-- para 2 -->
      <div class="row row-space">
        <div class="col-12">
          <span class="manual-text">We are not able to verify your account.</span>
        </div>
      </div>
      <!-- para 3 -->
      <div class="row row-space">
        <div class="col-12">
          <span class="manual-text">
            As a next step, please take a photo of
            <br />your Drivers License and upload
            <br />and a representative will follow up with you
            <br />to complete your registration.
          </span>
        </div>
      </div>
      <!-- upload document button that will enable the below section and hide this section -->
      <div class="row warning-icon-space padding">
        <div class="col-12">
          <button type="button" class="btn-upload" @click="moveToUploadDocument">Upload Document</button>
        </div>
      </div>
    </div>
    <!-- upload images section-->
    <div v-else>
      <!-- text section -->
      <div v-if="!hideText" class="row warning-icon-space">
        <div class="col-12">
          <span class="capture-text">
            We could't verify your account.
            <br />Please upload your Drivers License
            <br />for final verfication.
          </span>
        </div>
      </div>
      <!-- image front section -->
      <div v-if="url" class="row btn-row-space">
        <div class="col-12">
          <div>
            <img class="preview" :src="url" />
          </div>
        </div>
      </div>
      <!-- image back section -->
      <div v-if="backurl" class="row btn-row-space">
        <div class="col-12">
          <div>
            <img class="preview" :src="backurl" />
          </div>
        </div>
      </div>
      <!-- image upload section -->
      <div v-if="!backurl" class="row btn-row-space">
        <div class="col-12">
          <div class="upload-document" @click="openCamera">
            <input
              id="open_camera"
              @change="onFileChange"
              type="file"
              ref="camera"
              accept="image/*"
              capture
              hidden
            />
            <span>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 6.6 8.3"
                style="enable-background:new 0 0 6.6 8.3; margin-top: -9px;"
                xml:space="preserve"
                fill="#ffffff"
                width="125"
                height="125"
              >
                <rect />
                <path
                  d="M4.4,2.8L4.4,2.8C4.3,2.8,4.3,2.8,4.4,2.8L4.3,3.2H4l0,0C4,3.2,3.9,3.2,3.9,3.3c0,0,0,0.1,0.1,0.1h0.3v0.3
	c0,0.1,0,0.1,0.1,0.1s0.1,0,0.1-0.1l0,0V3.4h0.3c0.1,0,0.1,0,0.1-0.1c0-0.1,0-0.1-0.1-0.1l0,0H4.5V2.9C4.5,2.8,4.5,2.8,4.4,2.8
	L4.4,2.8L4.4,2.8z"
                />
                <path
                  d="M3.8,2.8L3.8,2.8H3.4c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.1l0,0C2.6,3,2.6,3,2.5,3.1
	c0,0-0.1,0.1-0.1,0.1H2.1c-0.2,0-0.4,0.2-0.4,0.4V5c0,0.2,0.2,0.4,0.4,0.4h1.2h1.2c0.2,0,0.4-0.2,0.4-0.4V3.7c0-0.1,0-0.1-0.1-0.1
	s-0.1,0-0.1,0.1l0,0V5c0,0.1-0.1,0.2-0.2,0.2H3.3H2.1C2,5.2,1.9,5.1,1.9,5V3.5c0-0.1,0.1-0.2,0.2-0.2h0.4c0.1,0,0.2-0.1,0.2-0.1
	C2.7,3.1,2.7,3,2.8,3c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0s0.1,0,0.1,0c0,0,0.4,0,0.5,0C3.9,2.9,3.9,2.9,3.8,2.8
	C3.9,2.9,3.9,2.8,3.8,2.8L3.8,2.8L3.8,2.8z"
                />
                <path
                  d="M3.3,3.7C3,3.7,2.8,3.9,2.8,4.2S3,4.7,3.3,4.7s0.5-0.2,0.5-0.5S3.6,3.7,3.3,3.7z M3.3,4.5C3.1,4.5,3,4.4,3,4.2
	C3,4,3.1,3.9,3.3,3.9c0.2,0,0.3,0.1,0.3,0.3C3.6,4.4,3.5,4.5,3.3,4.5z"
                />
              </svg>
            </span>
            <span v-if="!url" class="bank-linking-text camera-text">
              Place your Drivers License on a flat surface
              <br />and tap to capture the front
            </span>
            <span v-if="url" class="bank-linking-text camera-text">
              Now flip your Drivers License over, and tap
              <br />to capture the back
            </span>
          </div>
        </div>
      </div>
      <!-- next button section -->
      <div class="row icon-space">
        <div class="col-12">
          <button :disabled="!enableNext" type="button" class="btn-upload" @click="clickNext">Next</button>
        </div>
      </div>
    </div>
    <!-- loader modal -->
    <div>
      <b-modal
        ref="uploading-modal"
        hide-footer
        v-b-modal.modal-center
        modal-backdrop
        hide-header
        no-close-on-backdrop
        id="uploading-modal"
        centered
      >
        <div class="row text-center">
          <div class="col-12">
            <span class="text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width="100"
                height="100"
                viewBox="0 0 100 125"
                style="enable-background:new 0 0 100 125;"
                xml:space="preserve"
                fill="#ffffff"
              >
                <path
                  d="M34.1,35.1c-0.8-0.8-0.8-2,0-2.8l14.5-14.5c0,0,0,0,0,0c0.1-0.1,0.3-0.2,0.4-0.3c0,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.5-0.2
	c0,0,0,0,0,0c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.3,0.2,0.4,0.3
	c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1l14.5,14.5c0.8,0.8,0.8,2,0,2.8c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6L52,23.9v42
	c0,1.1-0.9,2-2,2s-2-0.9-2-2v-42L36.9,35.1C36.1,35.8,34.9,35.8,34.1,35.1z M83.4,49.2c-1.1,0-2,0.9-2,2v18.5c0,5.1-4.1,9.2-9.2,9.2
	H27.7c-5.1,0-9.2-4.1-9.2-9.2V50.9c0-1.1-0.9-2-2-2s-2,0.9-2,2v18.8c0,7.2,5.9,13.1,13.1,13.1h44.6c7.2,0,13.1-5.9,13.1-13.1V51.2
	C85.4,50.1,84.5,49.2,83.4,49.2z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="row text-upload text-center">
          <div class="col-12">
            <span class="success-text">Uploading...</span>
          </div>
        </div>
      </b-modal>
      <!-- loader modal end -->
    </div>
    <!-- success modal -->
    <div>
      <b-modal
        ref="upload-success-modal"
        hide-footer
        v-b-modal.modal-center
        no-close-on-backdrop
        hide-header
        id="upload-success-modal"
        centered
      >
        <div class="row text-center icon-space">
          <div class="col-12">
            <span class="text-center">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="enable-background:new 0 0 100 125;"
                xml:space="preserve"
                height="60"
                width="60"
                fill="#1b9142"
              >
                <g>
                  <g>
                    <path
                      d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                    />
                  </g>
                  <g>
                    <polygon points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		" />
                  </g>
                </g>
              </svg>
            </span>
          </div>
        </div>
        <div class="row icon-space text-center">
          <div class="col-12">
            <span class="modal-text">
              We've sent your ID to the CanPay for
              <br />final verification. Look for an email
              <br />update from our team within the next
              <br />24 hours.
              <br />
              <br />Thank you for enrolling in CanPay!
            </span>
          </div>
        </div>
        <div class="row icon-space padding mt-5">
          <div class="col-12 text-center">
            <button type="button" class="btn-ok" @click="clickOK">OK</button>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { isMobile } from "mobile-device-detect";
import api from "../../api/registration.js";
export default {
  name: "ManualReview",
  data() {
    return {
      screen: "warning",
      hideText: false,
      whichImage: "frontside",
      url: null,
      backurl: null,
      imageFront: "",
      imageBack: "",
      mediaStream: null,
      enableNext: false,
      currentUser: {},
    };
  },
  props: {
    sessionID: ""
  },
  created: function () {
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      
  },
  methods: {
    openCamera() {
      document.getElementById("open_camera").click();
    },
    onFileChange(e) {
      if (this.whichImage == "frontside") {
        this.imageFront = this.$refs.camera.files[0];
        this.url = URL.createObjectURL(this.imageFront);
        this.hideText = true;
        this.whichImage = "backside";
      } else {
        this.imageBack = this.$refs.camera.files[0];
        this.backurl = URL.createObjectURL(this.imageBack);
        this.enableNext = true;
      }
    },
    // changing the content of the screen
    moveToUploadDocument() {
      this.screen = "";
    },
    // this function uploades the pictures to the server and proceeds to next step
    clickNext() {
      this.$refs["uploading-modal"].show();
      let self = this;
      /*Initialize the form data*/
      let formData = new FormData();
      formData.append("fileFront", self.imageFront);
      formData.append("fileBack", self.imageBack);
      formData.append("sessionId", self.sessionID);
      api
        .uploadIDCardImage(formData)
        .then(response => {
          this.$refs["uploading-modal"].hide();
          this.$refs["upload-success-modal"].show();
          self.currentUser.id_validation_status = '202';
          localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
          if (response.code == 401) {
            alert(response.message);
            self.$router.go();
          }
        })
        .catch(err => {
          this.$refs["uploading-modal"].hide();
          alert(err.response.data.message);
        });
    },
    /**
     * Success Modal pops up on clicking the okay button it will redirect to the landing screen
     */
    clickOK() {
      this.$router.push("/pay");
    }
  }
};
</script>
<style scoped>
.camera-modal {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: white;
  z-index: 10;
}
.camera-stream {
  width: 100%;
}
.take-picture-button {
  display: block !important;
}
</style>
