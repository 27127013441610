<template>
  <div class="container position-relative">
    <div class="row">
      <div class="col-12 header-logo-div m-0">
        <a style="
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        padding: 0;" href="javascript:void(0)" @click="showDrawer">
          <i
            class="fa fa-2x fa-bars icon menuicon-header-style m-0"
            style="color: #000000; background-color: #ffffff !important;"
            aria-hidden="true"
            v-show="showmenuicon"
          ></i>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 2540 3175"
            style="enable-background:new 0 0 2540 3175;  margin-top:30px;"
            xml:space="preserve"
            height="30"
            width="30"
            v-show="!showmenuicon"
          >
            <g>
              <path
                d="M1878,2270c39,39,39,104,0,143c-40,40-104,40-144,0L662,1342c-39-40-39-104,0-144L1734,127c40-40,104-40,144,0
		            c39,39,39,104,0,143L878,1270L1878,2270z"
              />
            </g>
          </svg>
        </a>
        <img v-if="consumer_type == 'lite'" class="header-logo" src="../../../assets/images/canpay-logo-lite.png" />
        <img v-else class="header-logo" src="../../../assets/images/canpay-logo-new.png" />
      </div>
      <HeaderWheel class="rw-header mr-2"/>
    </div>
  </div>
</template>

<script>
import DrawerLayout from "vue-drawer-layout";
import HeaderWheel from "../../RewardWheel/HeaderWheel.vue";

export default {
  name: "Header",
  components: {
    DrawerLayout,
    HeaderWheel
  },

  data: () => ({
    open: true,
    showmenuicon: true,
    consumer_type: localStorage.getItem("consumer_login_response")
        ? JSON.parse(localStorage.getItem("consumer_login_response")).consumer_type
        : null,
  }),
  mounted() {
    let self = this;
    self.$root.$on("Menu Drawer Close", function(data) {
      self.showmenuicon = true;
    });

    self.$root.$on("Menu Drawer Open", function(data) {
      setTimeout(function() {
        self.showmenuicon = false;
      }, 30);
    });
  },
  methods: {
    showDrawer() {
      this.showmenuicon = false;
      this.$root.$emit("Menu Drawer", [""]);
    }
  }
};
</script>

<style>
.rw-header{
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}
</style>
