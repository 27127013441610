const commonConstants = {

    //registration steps
    enter_otp: 0,
    enter_name_phone: 1,
    enter_pin: 2,
    enter_more_details: 3,
    enter_ssn: 4,
    manual_review: 5,
    select_bank_linking: 6,

    //bank linking process
    manual: "Manual",
    finicity: "Finicity",
    // For V2 consumer registration, if we get Code 29 from Microbilt
    microbiltResponceCode29: "ManualBankCodeWithTwintynine",
    blacklistedAccountNumber: "Error:1001",
    blockedRoutingNumber: "Error:1003",
    //User status
    user_active: '701',
    user_active_new: '708',
    consumer: 'Consumer',
    forced_update: 1,
    forced_update_initiated_by_admin: 1,
    lite_consumer: 'lite',
    standard_consumer: 'standard',
    lite_consumer_address: 'address',
    lite_consumer_email: 'email',
    lite_consumer_dob: 'date_of_birth',
    lite_consumer_id_validation: 'id_validation',
    lite_consumer_add_bank: 'add_bank',
};

export default commonConstants;