<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div v-else>
    <div class="container updated-transaction-container mt-3">
      <div v-if="showModifyHistory" :style="updated_data.length > 1 ? 'height: 545px;' : 'height: 525px;'" :class="updated_data.length > 1 ? 'updated-transaction-slider' : 'updated-transaction-slider w-100'">
        <div 
        v-for="(up_data, index) in updated_data"
        :key="index"
        :class="updated_data.length > 1 ? 'updated-transaction-slider-card position-relative' : 'updated-transaction-slider-card position-relative mr-0 w-100'"
        >
          <div class="updated-transaction-slider-card-header">
              <h4 v-if="reasonTypeIncrease(up_data)">Payment Modification Alert</h4>
              <h4 v-else>Prepayment Modification Alert</h4>
          </div>
          <div class="updated-transaction-slider-card-body">
            <svg class="mb-3" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 55" xml:space="preserve" fill="#149240" width="70">
              <g>
                <path d="M63,0H1C0.4,0,0,0.4,0,1v32c0,0.6,0.4,1,1,1h62c0.6,0,1-0.4,1-1V1C64,0.4,63.6,0,63,0z M62,32H2V2h60V32z"></path>
                <path d="M6,26c1.7,0,3,1.3,3,3c0,0.6,0.4,1,1,1h44c0.6,0,1-0.4,1-1c0-1.7,1.3-3,3-3c0.6,0,1-0.4,1-1V9c0-0.6-0.4-1-1-1
                  c-1.7,0-3-1.3-3-3c0-0.6-0.4-1-1-1H10C9.4,4,9,4.4,9,5c0,1.7-1.3,3-3,3C5.4,8,5,8.4,5,9v16C5,25.6,5.4,26,6,26z M24,17
                  c0-6.1,3.6-11,8-11s8,4.9,8,11s-3.6,11-8,11S24,23.1,24,17z M53.1,6C53.5,8,55,9.5,57,9.9v14.2c-2,0.4-3.5,1.9-3.9,3.9H37.3
                  c3.1-2.8,4.8-6.8,4.7-11c0.1-4.2-1.6-8.2-4.7-11H53.1z M7,9.9C9,9.5,10.5,8,10.9,6h15.8c-3.1,2.8-4.8,6.8-4.7,11
                  c-0.1,4.2,1.6,8.2,4.7,11H10.9C10.5,26,9,24.5,7,24.1V9.9z"></path>
                <path d="M50,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S48.3,21,50,21z M50,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S49.4,17,50,17
                  z"></path>
                <path d="M14,21c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S12.3,21,14,21z M14,17c0.6,0,1,0.4,1,1s-0.4,1-1,1s-1-0.4-1-1S13.4,17,14,17
                  z"></path>
                <path d="M63,48H43v-4c0-0.3-0.1-0.5-0.3-0.7l-4-4c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-4,4C33.1,43.5,33,43.7,33,44v4H1
                  c-0.6,0-1,0.4-1,1s0.4,1,1,1h32v4c0,0.6,0.4,1,1,1h8c0.6,0,1-0.4,1-1v-4h20c0.6,0,1-0.4,1-1S63.6,48,63,48z M41,53h-6v-8.6l3-3l3,3
                  V53z"></path>
              </g>
            </svg>

            <div class="text-left">
              <div class="modification_text_group">
                <p style="white-space: break-spaces;text-align: center;font-size: 1rem;"><span class="text-black">Reason : </span> <span class="text-muted">{{reasonVIew(up_data.reason, 50)}}</span> <a v-if="reasonVIewMoreButton(up_data.reason, 50)" @click="showingFullTex(up_data.reason)" class="btn-link">More</a></p>
                <p style="white-space: break-spaces;text-align: center;font-size: 1rem;" v-if="additionalReasonChk(up_data.additional_reason)"><span class="text-muted">{{reasonVIew(up_data.additional_reason, 25)}}</span> <a v-if="reasonVIewMoreButton(up_data.additional_reason, 25)" @click="showingFullTex(up_data.additional_reason)" class="btn-link">More</a></p>
              </div>
              <div class="modification_text_group">
                <p><span class="text-black">Merchant Name :</span> <span class="text-muted">{{up_data.store_name}}</span></p>
                <p><span class="text-black">Merchant Phone :</span> <a :href="'tel:'+up_data.store_contact_no">{{up_data.store_contact_no}}</a></p>
              </div>
              <div class="modification_text_group">
                <p><span class="text-black">Original Payment Amount :</span> <span class="text-muted">${{up_data.amount}}</span> </p>
                <p><span class="text-black">Date : </span> <span class="text-muted">{{transactiontime(up_data)}}</span></p>
              </div>
              <div class="modification_text_group modification_text_highlight text-left">
                <p v-if="up_data.previous_approval_amount && !reasonTypeIncrease(up_data)"><span class="text-black">Prior Approved Amount :</span> <span class="text-muted">${{up_data.previous_approval_amount}}</span> </p>
                <p v-if="up_data.previous_approval_amount && reasonTypeIncrease(up_data)"><span class="text-black">Prior Payment Amount :</span> <span class="text-muted">${{up_data.previous_approval_amount}}</span> </p>
                <p v-if="up_data.last_updated_amount && !reasonTypeIncrease(up_data)"><span class="text-black">New Approved Amount :</span> <span class="text-muted">${{up_data.last_updated_amount}}</span> </p>
                <p v-if="reasonTypeIncrease(up_data)"><span class="text-black">New Payment Amount :</span> <span class="text-muted">${{up_data.updated_amount}}</span> </p>
                <p class="row m-0" v-if="reasonTypeIncrease(up_data)">
                  <span class="text-black mr-2">Time Left For Approval :</span>
                  <strong class="text-dark" v-if="reasonTypeIncrease(up_data)">
                    <vue-countdown-timer
                      :start-time="up_data.current_store_time"
                      :end-time="up_data.expiration_datetime"
                      :interval="1000"
                      :end-text="'Expired'"
                      :day-txt=false
                      :hour-txt="':'"
                      :minutes-txt="':'"
                      secondsTxt="">
                    </vue-countdown-timer>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div class="updated-transaction-slider-card-footer foot-btn">
            <div class="d-flex" v-if="reasonTypeIncrease(up_data)">
              <div class="col-6 pr-1">
                <button type="button" class="btn btn-primary center-block btn-black snackbar-button w-100 m-0" style="margin-right: 5px;" @click="acceptDeclineTransactionAmount(0, up_data.transaction_id, up_data.updated_amount, up_data.tip_amount)">
                Reject
                </button>
              </div>
              <div class="col-6 pl-1">
                <button type="button" class="btn btn-success center-block btn-green btn-green-font snackbar-button w-100" @click="acceptDeclineTransactionAmount(1, up_data.transaction_id, up_data.updated_amount, up_data.tip_amount )">
                Approve
                </button>
              </div>
            </div>
            <div class="text-center px-3 pb-2" v-else>
              <button type="button" class="btn btn-success thnku-btn" @click="updateTransactionRead(up_data.transaction_id, up_data.updated_amount)" >Thanks For Letting Me Know</button>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto" v-else>
        <enter-tip :transactiondetails="transactiondetails"></enter-tip>
      </div>
      
    </div>
  </div>
  
  <!-----------------------  MODAL FOR TRANSACTION DECLINED  !------------------------>
  <!--------------------------------------------------------------------------->
  <b-modal
    ref="transaction-modal"
    hide-footer
    v-b-modal.modal-center
    no-close-on-backdrop
    modal-backdrop
    hide-header
    id="transaction-modal"
    centered
    title="BootstrapVue"
  >
    <div class="color">
      <div class="col-12 text-center">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="120"
          height="120"
          viewBox="0 0 100 125"
          style="enable-background: new 0 0 100 125"
          xml:space="preserve"
          fill="#e14343"
        >
          <path
            d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
          />
        </svg>
      </div>
      <div class="d-block text-center">
        <label class="purchasepower-def-label">Transaction Declined</label>
      </div>
      <label
        class="purchasepower-modal-text text-center"
        style="margin: 10px"
        >{{ transactionModificationMessage }}</label
      >
      <br />
      <br />
      <div class="text-center">
        <button v-if="relink_banking_button == 1"
          type="button"
          class="mx-auto col-10 offset-1 btn-black"
          style="height: 60px"
          v-on:click="$router.push('/banklinking')"
        >
          <label class="purchasepower-modal-ok-label">Relink Banking</label>
        </button>
        <button v-else
          type="button"
          @click="hideModalRedirect('transaction-modal')"
          class="mx-auto col-10 offset-1 btn-black"
          style="height: 60px"
        >
          <label class="purchasepower-modal-ok-label">Close</label>
        </button>
      </div>
    </div>
  </b-modal>
  <!-----------------------  Simple MODAL  !------------------------>
  <b-modal
    ref="show-more-modal"
    hide-footer
    v-b-modal.modal-center
    no-close-on-backdrop
    modal-backdrop
    hide-header
    id="show-more-modal"
    centered
    title="BootstrapVue"
  >
    <div class="color">
      
      <div class="d-block text-center">
        <label class="purchasepower-def-label">Transaction Modification Reason</label>
      </div>
      <label
        class="text-center"
        style="margin: 10px; word-break: break-all;"
        >{{ transactionMoReasonMessage }}</label
      >
      <br />
      <br />
      <div class="text-center">
        <button
          type="button"
          @click="hideModal('show-more-modal')"
          class="mx-auto col-10 offset-1 btn-black"
          style="height: 60px"
        >
          <label class="purchasepower-modal-ok-label">Close</label>
        </button>
      </div>
    </div>
  </b-modal>

  <!-----------------------  MODAL FOR EMAIL AND PHONE NUMBER CHANGE  !----------------->
  <b-modal
    ref="accept-reject-alert-model"
    hide-footer
    v-b-modal.modal-center
    modal-backdrop
    no-close-on-backdrop
    :hide-header="hideModalHeader"
    :title="modalTitle"
    :show-close="false"
    header-class="modalHeader"
    id="accept-reject-alert-model"
    centered
  >
    <div class="color">
      <div class="purchaserpower-modal-text">
        <div class="d-block text-center">
          <label class="update-modal-title"><b>{{ alertTitle }}</b></label>
        </div>
        <div class="row" v-if="tipUpdateShow">
          <div class="col-12">
            <label class="modal-tip">
                <b>Do you want to update your TIP amount?</b>
            </label>
          </div>
          <div class="col-12">
            <input
              @input="tipAmountValidate"
              type="number"
              v-model="tip_amount"
              class="form-control amount_input numberonly"
            />
            <i class="fa fa-dollar fa-sm doller_icon"></i>
          </div>
        </div>
        <div class="row">
            <div class="col-6 pr-1">
              <button
              @click="hideModal('accept-reject-alert-model')"
              class="btn btn-danger btn-md center-block tip-cancel-btn"
              >
                <span class="forgetpassword-ok-label">Cancel</span>
              </button>
            </div>
            <div class="col-6 pl-1">
              <button
              @click="acceptDeclineApiCall"
              class="btn btn-danger btn-md center-block tip-ok-btn w-100"
              >
                <span class="forgetpassword-ok-label">{{
                  sendButtonTitle
                }}</span>
              </button>
            </div>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>
<script>
import { db } from "../firebaseConfig.js";
import api from "../api/transactiondetails.js";
import tip from "./Payment/Tip.vue";
import payment_api from "../api/payment.js";
import account from "../api/account.js";
import Loading from "vue-loading-overlay";
import VueLoadImage from "vue-load-image";
import moment from "moment";
import CanPayLoader from "./CustomLoader/CanPayLoader.vue"
export default {
  name: "UpdatedTransactionDetails",
  data() {
    return {
      isLoading : false,
      fullPage : true,
      showModifyHistory : true,
      transactiondetails: {},
      alertTitle: '',
      tipUpdateShow: false,
      tip_amount: 0,
      sendButtonTitle: '',
      updated_data: [],
      transactionModificationMessage:null,
      transactionMoReasonMessage:null,
      modalTitle: '',
      hideModalHeader: true,
      relink_banking_button:0
    };
  },
  created() {
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      
    
    this.beforeGetdata();
    this.otherAction();
  },
  components: {
    "enter-tip": tip,
    "vue-load-image": VueLoadImage,
    Loading,
    CanPayLoader
  },
  methods: {
    showModal(data) {
      this.$refs[data].show();
    },
    hideModal(data) {
       this.$refs[data].hide();
    },
    hideModalRedirect(data) {
      this.getModifyDataCheck();
      this.hideModal(data)
    },
    additionalReasonChk(additionalReaso) {
      if(additionalReaso){
        return true
      }else{
        return false;
      }
    },
    reasonVIew(reason, no_of_ch) {
      if(reason.length > no_of_ch){
        return `${reason.slice(0, no_of_ch).trim()}...`;
      }else{
        return reason;
      }
    },
    reasonVIewMoreButton(reason, no_of_ch) {
      return reason.length > no_of_ch ? true : false;
    },
    reasonTypeIncrease(up_data) {
      if( up_data.status == 'Awaiting Consumer Approval'){
        return true;
      }else{
        return false;
      }
    },
    transactiontime(up_data) {
      return moment
        .utc(up_data.transaction_time)
        .local()
        .format(" DD MMM, YYYY | hh:m A");
    },
    showingFullTex(reason) {
      let self = this;
      self.transactionMoReasonMessage = reason;
      self.showModal('show-more-modal');
    },
    beforeGetdata() {
      let self = this;
      var transactionModifiyDetails = window.location.href.split("/");
      transactionModifiyDetails=  transactionModifiyDetails.splice(-1, 1);
      if(transactionModifiyDetails[0].includes('?')){
        var decodeTransaction = transactionModifiyDetails[0].split("?");
        if(decodeTransaction[1].includes('=%3D')){
          decodeTransaction = decodeTransaction[1].split("=%3D");
          decodeTransaction = atob(decodeTransaction[0]);
          self.gettransactionId  = decodeTransaction.split("-")[1];
          self.getmodificationAmount = decodeTransaction.split("-")[3];
        }else{
          decodeTransaction = atob(decodeTransaction[1]);
          self.gettransactionId  = decodeTransaction.split("-")[1];
          self.getmodificationAmount = decodeTransaction.split("-")[3];
        }
        
      }
      self.getdata();
      if (localStorage.getItem("updated_transaction_data") != null) {
        self.updated_data = JSON.parse(localStorage.getItem("updated_transaction_data"));
        localStorage.removeItem("updated_transaction_data");
      }else{
        self.getModifyData();
      }
    },
    getdata() {
      let self = this;
      let ref = db
        .collection("users")
        .doc(String(String(this.currentUser.user_id)));
      ref.get().then((snapshot) => {
        ref.onSnapshot((convo) => {
          let source = convo.metadata.hasPendingWrites ? "Local" : "Server";
          let ref = db
            .collection("users")
            .doc(String(this.currentUser.user_id));
          ref.get().then((snapshot) => {
            if (snapshot.exists) {
              this.users = snapshot.data();
              const containsKey = (obj, key) => Object.keys(obj).includes(key);
              const hasTMName = containsKey(this.users, "transaction_modification");
              const hasTSName = containsKey(this.users, "transaction_successful");
              if (hasTSName == true && this.users.transaction_successful != null) {
                const isDeclined = containsKey(
                  this.users.transaction_successful,
                  "declined"
                );
                if (!isDeclined) {
                  self.setdata('transaction_successful');
                  self.transactiondetails = this.users.transaction_successful;
                  self.showModifyHistory = false;
                  self.isLoading = false;
                }else{
                  this.setdata('transaction_modification');
                  self.getModifyData();
                }
              } else if (hasTMName == true && this.users.transaction_modification != null) {
                this.setdata('transaction_modification');
                self.getModifyData();
              }
            }
          });
        });
      });
    },
    setdata(type) {
      var data = {};
      if(type == 'transaction_modification'){
        data = {
          transaction_modification: null,
        };
      }else if(type == 'transaction_successful'){
        data = {
          transaction_successful: null,
        };
      }else{
        return true;
      }
      var self = this;
      var washingtonRef = db
        .collection("users")
        .doc(String(this.currentUser.user_id));
      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update(data)
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
    getModifyDataCheck() {
      this.updated_data = this.updated_data.filter(object => {
                              return object.transaction_id !== this.transaction_id;
                            });
      this.isLoading = false;
      if(this.updated_data.length == 0){
        this.$router.push("/pay");
      }
    },
    getModifyData() {
      var self = this;
      self.isLoading = true;
      api
        .getMofifiedTransaction()
        .then(function (response) {
          if (response.code == 200) {
            if(response.data.length == 0){
              self.setdata('transaction_modification');
              self.$router.push("/pay");
            }else{
              self.updated_data = response.data;
              self.isLoading = false;
            }
          }
          
        })
        .catch(function (err) {
          if (err.response.data.message == 401) {
            self.$router.push("/pay");
          } else {
            self.nopurchasepower = true;
            self.purchasepower = err.response.data.message;
          }
        });
    },
    acceptDeclineTransactionAmount(consumer_approve, transactionId, modificationAmount, tip_amount)
    {
      let self = this;
      self.consumer_approve = consumer_approve;
      self.transaction_id = transactionId;
      self.updated_amount = modificationAmount;
      self.tip_amount = tip_amount;
      self.tipUpdateShow = false;
      if(consumer_approve == 0){
        self.alertTitle = 'You are rejecting the transaction update request. This will cancel the transaction. Do you want to proceed?';
        self.sendButtonTitle = 'Reject';
        self.modalTitle = "";
        self.hideModalHeader = true;
      }else{
        self.alertTitle = 'I approve this payment increase.';
        self.sendButtonTitle = 'Approve';
        self.modalTitle = "Approve Payment Increase";
        self.hideModalHeader = false;
        if(self.tip_amount > 0){
          self.tipUpdateShow = true;
        }
      }
      self.showModal('accept-reject-alert-model');
    },
    updateTransactionRead(transactionId, modificationAmount)
    {
      let self = this;
      self.isLoading = true;
      self.transaction_id = transactionId;
      var request = {
      consumer_approve :2,
      transaction_id: transactionId,
      updated_amount: modificationAmount
      };
        payment_api
        .consumerTransactionApproved(request)
        .then((response) => {
          if (response.code == 200) {
            setTimeout(() => {
              self.getModifyDataCheck();
            }, 500);
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          alert(error.response.data.message);
        });
    },
    acceptDeclineApiCall(){
      let self = this;
      self.hideModal('accept-reject-alert-model');
      self.isLoading = true;
      var request = {
        consumer_approve : self.consumer_approve,
        transaction_id: self.transaction_id,
        updated_amount: self.updated_amount,
        tip_amount: parseFloat(self.tip_amount)
      };
        payment_api
        .consumerTransactionApproved(request)
        .then((response) => {
          if (response.code == 200) {
            // if decline
            if(self.consumer_approve == 0){
              self.isLoading = false;
              self.transactionModificationMessage = response.message;
              setTimeout(() => {
                self.showModal("transaction-modal");
              }, 500);
            }
          }
        })
        .catch(function (error) {
          self.isLoading = false;
          self.transactionModificationMessage = error.response.data.message;
          self.relink_banking_button = error?.response?.data?.data?.relink_banking_button === 1 ? 1 : 0;
          self.showModal("transaction-modal");
          if(self.consumer_approve == 1){
            setTimeout(() => {
              self.setdata('transaction_successful');
            }, 1500);
          }
        });
    },
    otherAction() {
      $(document).on("keypress", ".numberonly", function (e) {
        var keyCode = e.which ? e.which : e.keyCode
        if ((keyCode != 46 || $(this).val().indexOf('.') != -1) && (keyCode < 48 || keyCode > 57)) {
          self.UpAmtMsg = true;
          self.UpAmtErrorMsg = 'Please fill in the required fields.';
          return false;
        } else {
          self.UpAmtMsg = false;
          return true;
        }
      });
    },
    tipAmountValidate(val){

      if(this.tip_amount.indexOf('.') !== -1)
      {
        var afterDot = this.tip_amount.substr( this.tip_amount.indexOf('.') + 1 );

        if(afterDot.toString().length > 2){
          this.tip_amount = parseFloat(this.tip_amount).toFixed(2)
        }else{
          this.tip_amount = this.tip_amount
        }
      }
      
    }
  },
  mounted() {
    var element = document.getElementsByClassName("content-wrap");
    if (element[0]) {
      element[0].style.setProperty("background-color", "#149240");
      element[0].style.height = "114vh";
    }
    this.$root.$emit("loginapp", [""]);
    this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
  },
};
</script>
<style lang="scss" scoped>
.updated-transaction-container{
  height: calc(100vh - 150px);
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-flow: column;
}
@media only screen and (max-width: 1300px) {
  .updated-transaction-container{
    height: calc(100vh - 150px);
  }
}
.updated-transaction-slider {
  height: 570px;
  /* line them up horizontally */
  display: flex;

  /* allow for scrolling */
  overflow-x: auto;

  /* make it smooth on iOS */
  -webkit-overflow-scrolling: touch;
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
}
.updated-transaction-slider > div {
  /* make sure the width is honored */
  flex-shrink: 0;
  width: 300px;
  // height: 300px;
  // line-height: 300px;
  text-align: center;
  background: #ffffff;
  margin-right: 15px;
  border-radius: 5px;
}
.updated-transaction-slider-card{
    height: 100%;
    border-radius: 0 0 6px 6px ;
}
.updated-transaction-slider-card-header{
  background-color: #000;
  padding: 20px 15px;
  border-radius: 6px 6px 0 0 ;
  font-size: 15px;
}
.updated-transaction-slider-card-header h4{
  color: #fff;
  margin-bottom: 0;
  font-size: 19px;
}
.updated-transaction-slider-card-body{
  padding: 15px;
  border-radius: 0 0 6px 6px ;
}
.foot-btn{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%!important;
}
.modification_text_group{
  margin-bottom: 1.1rem;
}
.modification_text_highlight{
  background-color: #b4b4b426;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}
.modification_text_highlight .timer{
  font-size: 1.3rem;
}
.modification_text_group p{
  margin-bottom: 0.1rem;
}
.amount_input{
  width: 100%;
  padding: 0px 30px !important;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
.doller_icon{
  position: absolute;
  left: 30px;
  top: 12px;
  color: gray;
}


</style>

<style>
#accept-reject-alert-model___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#accept-reject-alert-model___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#accept-reject-alert-model___BV_modal_body_ .update-modal-title {
  font-size: 1.1rem;
}
#accept-reject-alert-model___BV_modal_body_ .modal-tip {
  font-size: 0.9rem;
}

.tip-cancel-btn {
    width: 100%;
    padding: 10px 10px;
    height: 50px;
    background-color: black !important;
    border-color: black !important;
    display: inline-block;
    vertical-align: top;
}
.thnku-btn{
  color: #fff;
  background-color: #149240;
  border-color: #149240;
  width: 100%;
  height: 50px;
  margin: 0;
  border-radius: 5px;
}
.close {display: none;}
.modalHeader{
  background-color: black;
  color: white;
  justify-content: center;
}

</style>