<template>
    <div class="pb-2">
        <div >
            <p style="padding:  10px 17px 2px 17px;" :id="'range_text_'+ownedPoints" class="cp-point-convertion-text text-left mb-0"><span>Up to <strong class="text-white value">${{amountNumberFormatter(value*exchangeRate)}}</strong> will be paid with {{parseInt(selectedPoints) > 1 ? 'points' : 'points'}} </span></p>

            <div style="padding: 7px 17px 7px 17px;" class="row justify-content-between mx-2 margin-bottom-reduce">
              <span class="cp-range-text ml-4">0</span>
              <span class="cp-range-text mr-3">{{pointNumberFormatter(ownedPoints)}}</span>
            </div>


            <div v-if="(parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ) && consumer_type != 'lite'" style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between">
              <div style="margin-left: -7px;" @click="decreasePoint()" v-longclick="() => longPressDecreasePoint()" class="point-navigate-btn">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider">
                <CustomSlider
                v-model="value"
                :total-points="parseInt(ownedPoints)"
                :merchant-points="parseInt(merchantPoints)"
                :generic-points="parseInt(genericPoints)"
                :minimum-redeem-points="parseInt(minimumRedeemPoints)"
                :is-merchant-available="parseInt(merchantPoints) >= parseInt(minimumRedeemPoints) ? true : false"
                @input="selectPrice"
                @change="slideChange($event)"  
                />
              </div>
              <div style="margin-right: -7px;" @click="increasePoint()" v-longclick="() => longPressIncreasePoint()" class="point-navigate-btn">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>

            <div v-else style="padding: 0px 17px;" class="d-flex align-items-center justify-content-between mt-2">
              <div class="point-navigate-btn disabled">
                <svg width="32" height="6" viewBox="0 0 32 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="6" width="6" height="32" rx="3" transform="rotate(-90 0 6)" fill="white"/>
                </svg>
              </div>
              <div class="cp-range-slider-disabled">
                <div class="cp-slider-disabled"></div>
                <div class="slider-range-disabled-1"></div>
                <div class="slider-range-disabled-2"></div>
                <div class="slider-range-disabled-3"></div>
                <div class="slider-range-disabled-4"></div>
              </div>
              <div class="point-navigate-btn disabled">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="13" width="6" height="32" rx="3" fill="white"/>
                <rect y="19" width="6" height="32" rx="3" transform="rotate(-90 0 19)" fill="white"/>
                </svg>
              </div>
            </div>
            <!-- NEED LATER  -->
            <div style="padding: 10px 17px 0 17px;" class="rw-point-checkbox" v-if="merchantPointSelected > 0 || merchantPointAvailable > 0">
              <div class="rw-point-card merchant">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <div> {{merchantPointSelected > 0 ? merchantName + ' Points: ' : 'Merchant points are available' }} <strong v-if="merchantPointSelected > 0">{{ pointNumberFormatter(merchantPoints) }} </strong>
                  <svg class="ml-2" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 45 45" fill="none">
                    <path d="M45 22.5C45 34.9264 34.9264 45 22.5 45C10.0736 45 0 34.9264 0 22.5C0 10.0736 10.0736 0 22.5 0C34.9264 0 45 10.0736 45 22.5Z" fill="black"/>
                    <path d="M37.3065 28.5767C39.194 24.5672 38.4215 20.7332 38.2029 19.7162C37.9551 18.5674 37.3283 15.8529 35.0472 13.4165C33.9607 12.2716 32.6704 11.3409 31.2428 10.6727C29.3188 9.77272 27.679 9.59712 26.7826 9.50932C23.0876 9.14348 20.0339 10.2264 18.3285 11.0019C19.1588 10.2033 20.1079 9.53928 21.1417 9.03373C22.0552 8.59538 23.0236 8.28281 24.0204 8.1045C25.6526 7.79137 27.326 7.7592 28.969 8.00939C29.7269 8.12646 32.8899 8.67521 36.0456 11.1336C40.6444 14.7115 42.532 19.6796 41.4606 25.394C39.8281 34.1375 31.4761 39.2592 23.0074 37.0056C20.5149 36.3398 17.8912 34.7667 16.7251 33.2302C16.7834 33.2302 16.849 33.2155 16.8855 33.2375C17.177 33.4277 17.4612 33.6326 17.76 33.8228C22.4972 36.8081 27.351 36.83 32.1757 34.1594C32.7866 33.8204 33.367 33.4287 33.9103 32.9887C36.0019 31.2912 36.9566 29.3157 37.3065 28.5767Z" fill="white"/>
                    <path d="M41.8619 29.1034C41.8109 29.4107 41.7234 29.8497 41.5849 30.3692C40.5792 34.0861 38.2835 36.4348 37.2996 37.4152C33.6993 41.0078 29.5087 42.0467 27.592 42.5004C23.6492 43.4296 20.4643 43.0345 19.3201 42.8516C15.2024 42.1931 12.3746 40.5249 11.5074 39.9761C9.738 38.8608 8.15213 37.476 6.80661 35.8714C5.23513 34.005 4.00402 31.8749 3.16989 29.579C2.95854 28.9937 2.15686 26.6962 1.9528 23.55C1.76331 20.5502 2.21517 18.3112 2.32449 17.7917C2.68091 16.0946 3.25668 14.4515 4.03717 12.9042C4.44529 12.114 6.52237 8.25072 11.1138 5.36793C12.1779 4.69479 14.9619 3.11438 18.9047 2.5144C19.9469 2.35344 28.933 1.10959 35.3683 6.76543C38.3491 9.38482 40.1055 12.8895 40.3824 13.4529C40.8088 14.3232 41.1788 15.2201 41.4902 16.1382C41.3882 15.9992 40.6302 14.9309 40.6083 14.9016C37.8098 10.7384 34.5593 8.77753 34.5593 8.77753C33.3302 8.05264 32.0087 7.49882 30.6311 7.13126C26.251 5.94595 22.5633 6.99956 21.6887 7.26297C16.558 8.8068 13.5189 12.9115 13.3439 13.1529C10.2465 17.4405 10.3121 21.9184 10.3704 23.1622C10.5672 27.3474 12.3892 30.3034 13.22 31.496C13.2856 31.5765 13.3804 31.7009 13.497 31.8472C13.5844 31.9643 13.6719 32.0813 13.7594 32.1984C15.9312 35.0666 18.8099 37.064 22.0094 38.0591C24.3937 38.7911 26.9131 38.9659 29.375 38.5703C31.837 38.1746 34.1762 37.2189 36.2137 35.7763C38.0065 34.5032 39.0924 33.1788 39.7629 32.3594C40.6521 31.2765 41.3007 30.2083 41.5121 29.7839C41.5412 29.7327 41.8473 29.1034 41.8619 29.1034Z" fill="#FFCB11"/>
                    <path d="M18.4306 29.1693C14.5825 29.1693 12.6585 26.6158 12.6585 22.7013C12.6585 18.6551 14.6845 16.2187 18.6492 16.2187C20.1797 16.2187 21.4114 16.5845 22.3588 17.3601C23.2479 18.1576 23.7435 19.2405 23.8674 20.616H22.3224C21.7685 20.616 21.3676 20.3599 21.1271 19.8551C20.7044 18.9551 19.8736 18.4942 18.6565 18.4942C16.2806 18.4942 15.2238 20.177 15.2238 22.7086C15.2238 25.167 16.2369 26.9011 18.5763 26.9011C20.1797 26.9011 21.098 26.0158 21.3749 24.6256H23.8601C23.6342 27.523 21.6009 29.1693 18.4306 29.1693Z" fill="white"/>
                    <path d="M31.7171 16.248H27.3515C26.6155 16.248 26.0324 16.8919 26.0324 17.6309V29.1255H28.6561V24.5379H31.9065C34.4792 24.5379 35.8566 22.9282 35.8566 20.3893C35.8566 17.7114 34.4428 16.248 31.7171 16.248ZM31.4183 22.2112H28.8602V18.4796H31.5494C32.7738 18.4796 33.4006 19.1016 33.4006 20.3527C33.4079 21.6039 32.752 22.2331 31.4183 22.2112Z" fill="#FFCB11"/>
                    </svg>
                </div>
              </div>
            </div>

            <div style="padding: 0 17px 3px 17px;" class="rw-point-checkbox mt-2">
              <div class="rw-point-card generic">
                <div :class="parseInt(ownedPoints) >= parseInt(minimumRedeemPoints) ? 'rw-point mr-2' : 'rw-point disabled mr-2'"></div>
                <span>CanPay Points: <strong>{{pointNumberFormatter(genericPoints)}} </strong>
                  <svg class="ml-2" width="22" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="35" cy="35" r="35" fill="black"/>
                  <path d="M58.0327 44.4525C60.969 38.2154 59.7673 32.2515 59.4272 30.6694C59.0417 28.8825 58.0667 24.66 54.5183 20.8699C52.8282 19.0889 50.821 17.6413 48.6004 16.6018C45.6075 15.2019 43.0567 14.9287 41.6622 14.7921C35.9144 14.223 31.1643 15.9075 28.5114 17.114C29.8031 15.8716 31.2794 14.8387 32.8875 14.0523C34.3086 13.3704 35.8149 12.8842 37.3656 12.6069C39.9046 12.1197 42.5076 12.0697 45.0633 12.4589C46.2423 12.641 51.1626 13.4946 56.0714 17.3188C63.225 22.8844 66.1613 30.6125 64.4947 39.5016C61.9553 53.1026 48.9632 61.0697 35.7897 57.5641C31.9125 56.5284 27.8312 54.0814 26.0173 51.6912C26.108 51.6912 26.2101 51.6685 26.2667 51.7026C26.7202 51.9985 27.1624 52.3172 27.6272 52.6131C34.9962 57.2568 42.5465 57.291 50.0515 53.1367C51.0019 52.6094 51.9047 52.0001 52.7497 51.3156C56.0034 48.6751 57.4885 45.6021 58.0327 44.4525Z" fill="white"/>
                  <path d="M65.1182 45.2722C65.0389 45.7502 64.9028 46.4331 64.6874 47.2412C63.1229 53.023 59.5518 56.6765 58.0213 58.2016C52.4209 63.79 45.9022 65.4062 42.9206 66.1119C36.7873 67.5573 31.8331 66.9427 30.0532 66.6582C23.6479 65.6338 19.2492 63.0388 17.9001 62.1852C15.1477 60.4502 12.6808 58.2961 10.5878 55.8001C8.14327 52.8969 6.22821 49.5834 4.93068 46.012C4.60191 45.1014 3.35485 41.5276 3.03741 36.6335C2.74266 31.9671 3.44554 28.4843 3.6156 27.6762C4.17003 25.0362 5.06567 22.4803 6.27977 20.0733C6.91463 18.8441 10.1456 12.8346 17.2879 8.35027C18.9431 7.30316 23.2738 4.84474 29.407 3.91145C31.0282 3.66105 45.0066 1.72618 55.0171 10.5242C59.6539 14.5988 62.386 20.0505 62.8168 20.9269C63.4801 22.2806 64.0557 23.6759 64.54 25.104C64.3813 24.8877 63.2023 23.226 63.1683 23.1805C58.8149 16.7044 53.7587 13.6541 53.7587 13.6541C51.8468 12.5265 49.7911 11.665 47.6481 11.0932C40.8346 9.24941 35.0981 10.8884 33.7377 11.2981C25.7565 13.6996 21.0291 20.0847 20.757 20.4603C15.9388 27.1299 16.0408 34.0954 16.1315 36.0303C16.4376 42.5406 19.2718 47.1387 20.5642 48.9939C20.6663 49.1191 20.8137 49.3126 20.995 49.5403C21.1311 49.7224 21.2671 49.9045 21.4032 50.0866C24.7816 54.5482 29.2596 57.6553 34.2365 59.2032C37.9454 60.3419 41.8646 60.6138 45.6942 59.9984C49.5239 59.3829 53.1626 57.8962 56.3321 55.6522C59.121 53.6718 60.8102 51.6117 61.8532 50.337C63.2363 48.6525 64.2453 46.9908 64.5741 46.3306C64.6194 46.251 65.0956 45.2722 65.1182 45.2722Z" fill="#007EE5"/>
                  <path d="M28.7061 45.6C22.7202 45.6 19.7273 41.6278 19.7273 35.5387C19.7273 29.2447 22.879 25.4546 29.0462 25.4546C31.427 25.4546 33.3429 26.0237 34.8167 27.2301C36.1998 28.4707 36.9707 30.1552 37.1634 32.2949H34.76C33.8984 32.2949 33.2749 31.8966 32.9008 31.1112C32.2432 29.7113 30.9508 28.9943 29.0576 28.9943C25.3617 28.9943 23.7179 31.612 23.7179 35.5501C23.7179 39.3743 25.2937 42.0717 28.9329 42.0717C31.427 42.0717 32.8554 40.6945 33.2862 38.532H37.1521C36.8007 43.0392 33.6377 45.6 28.7061 45.6Z" fill="white"/>
                  <path d="M49.3373 25.2747H42.5465C41.4015 25.2747 40.4945 26.2762 40.4945 27.4258V45.3063H44.5758V38.17H49.632C53.634 38.17 55.7766 35.6661 55.7766 31.7166C55.7766 27.551 53.5773 25.2747 49.3373 25.2747ZM48.8725 34.5507H44.8932V28.746H49.0765C50.9811 28.746 51.9561 29.7135 51.9561 31.6597C51.9674 33.606 50.9471 34.5848 48.8725 34.5507H48.8725Z" fill="#007EE5"/>
                  </svg>
                </span>
              </div>
            </div>
        </div> 
    </div>
</template>

<script>
import Vue from 'vue'

import CustomSlider from './CustomSlider.vue';

export default {
    props:{
        value: Number,
        ownedPoints: {
            type: Number,
            default: 0
        },
        disabled:{
          type: Boolean,
          default: false
        },
        exchangeRate: {
            type: Number,
            default: 0
        },
        minimumRedeemPoints: {
            type: Number,
            default: 0
        },
        genericPoints: {
            type: Number,
            default: 0
        },
        merchantPoints: {
            type: Number,
            default: 0
        },
        merchantName: {
            type: String,
            default: null
        },
        merchantPointAvailable: {
            type: Number,
            default: 0
        },
        merchantPointSelected: {
            type: Number,
            default: 0
        },
    },
    components: {
      CustomSlider
    },
    directives:{
      'longclick': {
        bind: function (el, binding, vNode) {
          if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[longclick:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}' ` }
            console.warn(warn) // eslint-disable-line
          }

          let pressTimer = null
          let pressInterval = null

          const start = (e) => {

            if (e.type === 'click' && e.button !== 0) {
              return
            }

            if (pressTimer === null) {
              pressTimer = setTimeout(() => {
                if (50 && 50 > 0) {
                  pressInterval = setInterval(() => {
                    handler()
                  }, 40)
                }
                handler()
              }, 400)
            }
          }

          // Cancel Timeout
          const cancel = () => {
            if (pressTimer !== null) {
              clearTimeout(pressTimer)
              pressTimer = null
            }
            if (pressInterval) {
              clearInterval(pressInterval)
              pressInterval = null
              vNode.context.onReleaseLongPress()
            }
          }
          // Run Function
          const handler = (e) => {
            binding.value(e)
          }

          ;['mousedown', 'touchstart'].forEach(e => el.addEventListener(e, start))
          ;['click', 'touchend', 'touchcancel'].forEach(e => el.addEventListener(e, cancel))
        }
      }
    },  
    data(){
        return{
            selectedPoints: 0,
            minimumSpendPoints: null,
            minimumRedeemValue: 0,
            selectedAmount: 0,
            totalSelectedPoint: 0,
            pressingTimeInseconds: 0,
            maxSelectionPoint: 0,
            consumer_type: localStorage.getItem("consumer_login_response")
              ? JSON.parse(localStorage.getItem("consumer_login_response")).consumer_type
              : null,
        }
    },
    mounted(){
        var self = this;
        self.minimumSpendPoints = self.value
        self.selectedPoints = self.value
        self.minimumRedeemValue = self.minimumRedeemPoints

        self.maxSelectionPoint = parseInt(self.ownedPoints/self.minimumRedeemPoints) * self.minimumRedeemPoints
    },
    methods:{
        decreasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.$emit('change', parseInt(0));
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressDecreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) - parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) - (parseFloat(this.minimumRedeemPoints)*150)
          }
          
          if(this.totalSelectedPoint < 0){
            this.$emit('input', 0);
            this.totalSelectedPoint = 0
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', this.totalSelectedPoint);
        },
        increasePoint(){
          this.totalSelectedPoint = 0
          this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.totalSelectedPoint) - parseFloat(this.minimumRedeemPoints)
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
          this.$emit('change', parseInt(this.totalSelectedPoint));
        },
        longPressIncreasePoint(){

          this.pressingTimeInseconds += 1

          this.totalSelectedPoint = 0
          if(this.pressingTimeInseconds < 30){
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }else if(this.pressingTimeInseconds >= 30 && this.pressingTimeInseconds < 80){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*50)
          }else if(this.pressingTimeInseconds >= 80 && this.pressingTimeInseconds < 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*100)
          }else if(this.pressingTimeInseconds >= 130){
            this.totalSelectedPoint = parseFloat(this.value) + (parseFloat(this.minimumRedeemPoints)*150)
          }else{
            this.totalSelectedPoint = parseFloat(this.value) + parseFloat(this.minimumRedeemPoints)
          }
          this.maxSelectionPoint = parseInt(this.ownedPoints/this.minimumRedeemPoints) * this.minimumRedeemPoints;
          if(this.totalSelectedPoint > this.ownedPoints){
            this.totalSelectedPoint = parseFloat(this.maxSelectionPoint)
            this.$emit('input', parseInt(this.totalSelectedPoint));
            this.selectedPoints = this.totalSelectedPoint
            return
          }
          this.selectedPoints = this.totalSelectedPoint
          this.$emit('input', parseInt(this.totalSelectedPoint));
        },
        selectPrice(event){
          var self = this;
          self.selectedPoints = event
          self.$emit('input', parseInt(event));
          self.selectedAmount = event*self.exchangeRate
        },
        slideChange: function(event) {
            var self = this;
            self.$emit('input', parseInt(event));
            self.$emit('change', parseInt(event));
            self.selectedAmount = event*self.exchangeRate
        },
        onReleaseLongPress() {
          this.pressingTimeInseconds = 0
          this.$emit('change', parseInt(this.totalSelectedPoint));
        }
    }
}
</script>


<style lang="scss">
.cp-range-slider{
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}

.cp-slider-disabled{
  -webkit-appearance: none;
  width: 100%;
  height: 20px;
  background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%)!important;
  background-color: #EFEFEF!important;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border: 1px solid #000;
  border-radius: 50px;
  cursor: no-drop;
}
.cp-range-slider-disabled{
  width: 90%;
  display: flex;
  align-items: center;
  position: relative;
}
.slider-range-disabled-1{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 20%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-2{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 40%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-3{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 60%;
  position: absolute;
  bottom: 0;
}
.slider-range-disabled-4{
  width: 2px;
  height: 8px;
  background-color: black;
  left: 80%;
  position: absolute;
  bottom: 0;
}



.cp-range-text{
  color: #ffffff;
  font-size: 13px !important;
  font-weight: 600;
}
.point-navigate-btn{
  background-color: #0e5e2d;
  border: 0;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  cursor: pointer;
}
.point-navigate-btn.disabled{
  cursor: no-drop;
}
.rw-point-checkbox{
  display: flex;
  justify-content: center;
}
.rw-point-card{
  width: 92%;
  display: flex;
}
.rw-point-card.generic{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.generic .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #007ee5;
  border: 1px solid #000;
}
.rw-point-card.merchant{
  font-size: 13px;
  display: flex;
  align-items: center;
}
.rw-point-card.merchant .rw-point{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #ebb700;
  border: 1px solid #000;
}
.rw-point.disabled{
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #C7D0D7!important;
  border: 1px solid #000;
}
.cp-range-disabled-input{
  background-color: #ACACAC;
  height: 20px;
  width: 100%;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: no-drop;
}
.margin-bottom-reduce{
  margin-bottom: -5px;
}
.merchant-points-range{
  width: 20%;
  height: 20px;
  position: absolute;
  background: white;
  z-index: 1;
  border-radius: 5px 0 0 5px;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
@media only screen and ( min-width:280px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 8px;
  }
  .rw-point-card.merchant{
    font-size: 8px;
  }
}
@media only screen and ( min-width:320px) and ( max-width:700px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 80%;
  }
  .rw-point-card.generic{
    font-size: 9px;
  }
  .rw-point-card.merchant{
    font-size: 9px;
  }
}
@media only screen and ( min-width:376px) and ( max-width:800px) {
  .cp-range-slider {
    width: 80%;
  }
  .cp-range-slider-disabled{
    width: 80%;
  }
  .rw-point-card{
    width: 84%;
  }
  .rw-point-card.generic{
    font-size: 11px;
  }
  .rw-point-card.merchant{
    font-size: 11px;
  }
}
</style>