<template>
<div>
  <div v-if="isLoading">
    <CanPayLoader/>
  </div>
  <div>
    <!-- this div is visible when finicity portal is connected -->
      <div
        id="connect-container"
        v-if="showFloatDiv && !isLoading"
        class="d-flex justify-content-start iframe-header"
      >
      </div>
    
    <div class="container">
      <div v-if="show === constants.enter_more_details">
        <div class="progress-bar-row">
          <div class="row">
            <div class="col-4 pad-0" >
              <label v-if="progress === 33" class="progress-label-40 float-right"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-4 pad-0">
              <label v-if="progress === 66" class="progress-label-80 float-right"
                >{{ progress }}%</label
              >
            </div>
            <div class="col-4">
              <label v-if="progress === 100" class="progress-label-100 float-right"
                >{{ progress }}%</label
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-progress
                height="4px"
                :value="progress"
                :max="progressMax"
                class="mb-3 progress-bar-element"
              ></b-progress>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <svg
                @click="showInfoModal"
                class="info-icon"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 125"
                style="enable-background: new 0 0 100 125"
                xml:space="preserve"
                width="30"
                height="30"
                fill="#ffffff"
              >
                <title>A__64</title>
                <path
                  d="M50,5C25.1,5,5,25.1,5,50s20.1,45,45,45s45-20.1,45-45C95,25.2,74.8,5,50,5z M50,87c-20.4,0-37-16.6-37-37s16.6-37,37-37
                s37,16.6,37,37C87,70.4,70.4,87,50,87z M45,25c0-2.8,2.2-5,5-5s5,2.2,5,5s-2.2,5-5,5S45,27.8,45,25z M61,70.4V73c0,1.1-0.9,2-2,2H41
                c-1.1,0-2-0.9-2-2v-2.6c0-0.9,0.6-1.6,1.4-1.9l4.1-1.3c0.3-0.1,0.5-0.4,0.5-0.7V46h-3.1c-1.6,0-2.9-1.3-2.9-2.9c0-1.2,0.8-2.3,2-2.7
                l11.4-3.6c1.1-0.3,2.2,0.3,2.5,1.3c0.1,0.2,0.1,0.4,0.1,0.6v27.8c0,0.3,0.2,0.6,0.5,0.7l4.1,1.3C60.4,68.7,61,69.5,61,70.4z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div v-if="show === constants.enter_more_details">
        <enter-more-details
          :dobDayProp="day"
          :fulladdressProp="fulladdress"
          :addressProp="UserModel.address"
          :stateProp="UserModel.state"
          :cityProp="UserModel.city"
          :zipProp="UserModel.zip"
          :monthNumberProp="month_number"
          :dobMonthProp="dob_month"
          :dobYearProp="year"
          :emailProp="UserModel.email"
          :aptNumberProp="UserModel.aptNumber"
          :liteToStandardCurrentStep="lite_to_standard_current_step"
          :liteToStandardNextStep="lite_to_standard_next_step"
        ></enter-more-details>
      </div>

      <div v-else-if="show === constants.manual_review">
        <manual-review :sessionID="sessionID" v-if="!isLoading"></manual-review>
      </div>

      <div class="row" v-else-if="show === constants.enter_ssn">
        <enter-ssn v-if="!isLoading"></enter-ssn>
      </div>
    </div>
    
      <!-- Modal for info icon -->
      <div>
        <b-modal
          ref="info-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="info_modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  <b>Know more about your registration progress</b>
                </label>
              </div>
              <h3 class="purchasepower-modal-text text-center">
                This progress bar shows the completion of your enrollment
                process. Usually it takes less than 1 minute to complete.
              </h3>
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="hideInfoModal"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          ref="details-success-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="details-success-modal"
          centered
          title="BootstrapVue"
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="pin-success-top-spacing"></div>
              <div class="row" style="margin-bottom: 5px">
                <div class="col-4"></div>
                <div class="col-4 text-center">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 100 125"
                    style="enable-background: new 0 0 100 125"
                    xml:space="preserve"
                    height="80"
                    width="80"
                    fill="#1b9142"
                    class="succes-svg-padding"
                  >
                    <g>
                      <g>
                        <path
                          d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
      C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                        />
                      </g>
                      <g>
                        <polygon
                          points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="col-4"></div>
              </div>
              <div class="d-block text-center">
                <label class="success-popup-style">Success!</label>
              </div>
              <div class="d-block text-center">
                <label class="quick-pin-created-style"
                  >{{error_message}}</label
                >
              </div>
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="goToNextStep"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>
        <!-- Validation modal -->
        <b-modal
          ref="validation-modal"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          id="validation-modal"
          centered
        >
          <div class="color">
            <div class="purchaserpower-modal-text">
              <div class="d-block text-center">
                <label class="purchasepower-def-label">
                  {{ error_message }}
                </label>
              </div>
              <br />
              <br />
              <div class="text-center">
                <button
                  type="button"
                  class="mx-auto col-10 offset-1 btn-black"
                  style="height: 60px"
                  @click="hideValidationModal"
                >
                  <label class="purchasepower-modal-ok-label">OK</label>
                </button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    <div id="overlay" style="display: none;"></div>
  </div>
</div>
</template>

<script>
/**
 * Registration component is using for consumer registration process
 */
import api from "../../api/registration.js";
import constants from "../Common/constant.js";
import EnterMoreDetails from "./EnterMoreDetails.vue";
import ManualReview from "./ManualReview.vue";
import EnterSSN from "./EnterSSN.vue";
import Toasted from "vue-toasted";
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";

export default {
  name: "RegistrationToStandard",
  components: {
    "enter-more-details": EnterMoreDetails,
    "manual-review": ManualReview,
    "enter-ssn": EnterSSN,
    Loading,
    CanPayLoader
  },
  /**
   * @description-
   * first_name => this will store the user's first_name
   * middle_name => this will store the user's middle_name
   * last_name => this will store the user's last_name
   * phone => this will store the user's phone
   * email => this will store the user's email
   * @returns {any}
   */
  data() {
    return {
      constants: constants,
      progressMax: 100,
      progress: 33,
      suffix: "Suffix",
      show: 0,
      sessionID: "",
      isLoading: false,
      fullPage: true,
      showFloatDiv: false,
      fulladdress: "",
      day: "",
      month_number: "",
      dob_month: "",
      year: "",
      ssn: "",
      error_message: "",
      UserModel: {
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        email: "",
        firstnameColor: "",
        middlenameColor: "",
        lastnameColor: "",
        phoneColor: "",
        emailColor: "",
        address: "",
        state: "",
        city: "",
        zip: "",
        routingNumber: "",
        accountNumber: "",
      },
      lite_to_standard_current_step: '',
      lite_to_standard_next_step: '',
      currentUser: {},
    };
  },
  created: function () {
    this.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      
  },
  mounted: function () {
    let self = this;
    self.lite_to_standard_current_step = localStorage.getItem("lite_to_standard_current_step");
    this.$root.$emit("show_header", false);
    this.$root.$emit("changeWhiteBackground", [false, false, "RegistrationToStandardHeader"]);
    //catching the dob/address/ssn data when emitted
    // Remove existing listeners before adding new ones
    this.$root.$off("load_loader");
    this.$root.$off("goto_next_step");
    this.$root.$off("make_id_validation");
    this.$root.$off("emit_ssn");

    this.$root.$on("load_loader", function (data) {
      self.isLoading = data;
    });
    this.$root.$on("goto_next_step", function (data) {
     self.goToNextStep();
    });
    this.$root.$on("make_id_validation", function (data) {
      self.sessionID = data;
      self.makeidvalidation();
    });
    this.$root.$on("emit_ssn", function (data) {
      self.ssn = data;
      self.getAssessment();
    });
  },
  watch: {
    lite_to_standard_current_step: function (newval, oldval) {
      if (newval == this.constants.lite_consumer_id_validation) {
        this.completeRegistration();
      } else {
        this.show = constants.enter_more_details;
        //set the progress value
        const requirements = [
          this.currentUser.address_required,
          this.currentUser.email_required,
          this.currentUser.dob_required,
        ];
        const count = requirements.filter((value) => value === 1).length;

        if (count === 3) {
          this.progress = 33;
        } else if (count === 2) {
          this.progress = 66;
        } else {
          this.progress = 100;
        }
        // Determine the next step
        let nextStep = '';
        if (newval === this.constants.lite_consumer_address) {
          if (this.currentUser.email_required === 1) {
            nextStep = this.constants.lite_consumer_email;
          } else if (this.currentUser.dob_required === 1) {
            nextStep = this.constants.lite_consumer_dob;
          } else if (this.currentUser.bank_details_required === 1) {
            nextStep = this.constants.lite_consumer_add_bank;
          }
        } else if (newval === this.constants.lite_consumer_email) {
          if (this.currentUser.address_required === 1) {
            nextStep = this.constants.lite_consumer_address;
          } else if (this.currentUser.dob_required === 1) {
            nextStep = this.constants.lite_consumer_dob;
          } else if (this.currentUser.bank_details_required === 1) {
            nextStep = this.constants.lite_consumer_add_bank;
          }
        } else if (newval === this.constants.lite_consumer_dob) {
          if (this.currentUser.address_required === 1) {
            nextStep = this.constants.lite_consumer_address;
          } else if (this.currentUser.email_required === 1) {
            nextStep = this.constants.lite_consumer_email;
          } else if (this.currentUser.bank_details_required === 1) {
            nextStep = this.constants.lite_consumer_add_bank;
          }
        }
        this.lite_to_standard_next_step = nextStep;
      }
    },
  },
  methods: {
    showModal(data) {
      this.$refs[data].show();
    },
    showValidationModal(msg) {
      this.error_message = msg;
      this.$refs["validation-modal"].show();
    },
    hideValidationModal() {
      this.error_message = "";
      this.$refs["validation-modal"].hide();
      this.$router.push("/pay");
    },
    showInfoModal() {
      this.$refs["info-modal"].show();
    },
    
    hideInfoModal() {
      this.$refs["info-modal"].hide();
    },
    goToNextStep() {
      let self = this;
      self.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      if(self.lite_to_standard_next_step != ''){
        if (self.lite_to_standard_next_step == self.constants.lite_consumer_add_bank) {
          localStorage.setItem("lite_to_standard_current_step", self.lite_to_standard_current_step);
          self.$router.push("/banklinking");
        } else {
          self.lite_to_standard_current_step = self.lite_to_standard_next_step;
        }
      } else {
        self.$router.push("/pay");
      }
    },
    completeRegistration() {
      var self = this;
      self.isLoading = true;
      const payload = {
        is_lite_registration:true
		  }
      api.completeRegistration(payload)
      .then((response) => { 
        self.isLoading = false;
      })
      .catch(function (error) {
        self.isLoading = false;
        if (error.response.data.code == 308) {
          if (error.response.data.data.steps_completed == null) {
            self.showValidationModal(error.response.data.message);
          } else {
            if (error.response.data.data.steps_completed == self.constants.select_bank_linking) {
              self.updateConsumerLiteToStandard();
              self.goToNextStep();
            } else {
              self.show = error.response.data.data.steps_completed;
              self.sessionID = error.response.data.data.session_id;
              if (error.response.data.data.steps_completed == self.constants.manual_review) {
                self.currentUser.id_validation_required = 1;
                self.currentUser.id_validation_status = '';
                localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
              }
            }
          }
        }
      });
    },
    
    /**
     * makeidvalidation
     * @description-
     * This method will be called when user is redirected to id validation page from lite to standard registration flow.
     * @param {string} sessionID - session id of user
     * @returns {void}
     */
     makeidvalidation() {
      let self = this;
      self.currentUser = localStorage.getItem("consumer_login_response")
      ? JSON.parse(localStorage.getItem("consumer_login_response"))
      : null;
      var request = {
        email: self.currentUser.email,
        dateOfBirth: moment(String(self.currentUser.date_of_birth)).format( "MM-DD-YYYY" ),
        address: self.currentUser.street_address,
        city: self.currentUser.city,
        state: self.currentUser.state,
        zip: self.currentUser.zipcode,
        aptNumber: self.currentUser.apt_number,
        sessionId: self.sessionID,
        is_lite_to_standard_registration: true,
      };
      self.isLoading = true;
      api
        .makeIdvalidation(request)
        .then((response) => {
          self.isLoading = false;
          self.error_message = response.message;
          if (response.data != null) {
            if (response.data == self.constants.select_bank_linking) {
              self.updateConsumerLiteToStandard();
              self.showModal('details-success-modal');
            } else {
              self.show = response.data;
            }
          } else {
            self.showModal('details-success-modal');
          }
        })
        .catch((err) => {
          self.isLoading = false;
          if (err.response.data.data == 511) {
            self.currentUser.date_of_birth = null;
            self.currentUser.dob_required = 1;
            self.currentUser.id_validation_required = 0;
          } else if (err.response.data.data == 512) {
            self.currentUser.apt_number = null;
            self.currentUser.street_address = null;
            self.currentUser.city = null;
            self.currentUser.state = null;
            self.currentUser.zipcode = null;
            self.currentUser.address_required = 1;
            self.currentUser.id_validation_required = 0;
          }
          localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
          self.showValidationModal(err.response.data.message);
        });
    },

    /**
     * @description-
     * getAssessment => API call to get the asessment for last identity search with ssn
     * @returns {any}
    */
    getAssessment() {
      let self = this;
      self.isLoading = true;
      var request = {
        ssn: this.ssn,
        sessionId: this.sessionID,
      };
      api
        .getAssessment(request)
        .then((response) => {
          self.isLoading = false;
          if (response.code == 200) {
            self.error_message = response.message;
            if (response.data != null) {
              if (response.data == self.constants.select_bank_linking) {
                self.updateConsumerLiteToStandard();
                self.showModal('details-success-modal');
              } else {
                self.show = response.data;
              }
            } else {
              self.showModal('details-success-modal');
            }
          }
        })
        .catch((err) => {
          console.log("error", err);
          self.isLoading = false;
          self.showValidationModal(err.response.data.message);
        });
    },
    
    /**
     * Call API to update consumer from lite to standard and update the consumer_type in localstorage
     * and then go to the next step
    */
    updateConsumerLiteToStandard() {
      let self = this;
      self.isLoading = true;
      api
        .updateConsumerLiteToStandard()
        .then((response) => {
          self.isLoading = false;
          if (response.data.status == 200) {
            self.currentUser.consumer_type = self.constants.standard_consumer;
            self.currentUser.id_validation_required = 0;
            localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
          } else if (response.data.status == 202){
            self.currentUser.id_validation_required = 0;
            localStorage.setItem("consumer_login_response", JSON.stringify(self.currentUser));
          }
        })
        .catch((err) => {
          self.isLoading = false;
        });
    },
    
  },
};
</script>
<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::placeholder {
  color: rgb(0, 0, 0);
  opacity: 1;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #000000 !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  // letter-spacing: 0.5px !important;
  text-align: left !important;
}
#details-success-modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#details-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media only screen and (min-width: 300px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (width: 360px) and (width: 640px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -5px;
    width: 94px;
  }
}
@media only screen and (min-width: 360px) and (min-height: 700px) {
  .suffix-modal-sm {
    float: right !important;
  }
}
@media only screen and (max-width: 375px) and (max-width: 812px) {
  .suffix-modal-sm {
    float: right !important;
    margin-top: -58px;
    width: 95px;
  }
}
@media only screen and (width: 600px) and (height: 960px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 100px;
    margin-top: -110px;
  }
}
@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 120px;
    margin-top: -190px;
  }
}
@media (min-width: 1281px) {
  .suffix-modal-sm {
    float: right !important;
    margin-right: 293px;
    margin-top: 14px;
  }
}
#suffix-modal___BV_modal_content_ {
  margin-top: 165px;
  height: 238px;
  width: 66px;
}
#suffix-modal___BV_modal_body_ {
  background-color: white !important;
  border-radius: 4px;
}
#info_modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#info_modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#pin-success-modal___BV_modal_content_ {
  border-radius: 10px;
  margin: 10px;
  background-color: #ffffff;
}
#pin-success-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#sign-in-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
#validation-redirect-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>
