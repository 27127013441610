const loginUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('login', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const refreshBalance = (request) => {
    return new Promise((res, rej) => {
        axios.post('refreshbalance', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkPhonenumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('check/phoneNumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const verifyPhonenumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('verify/phoneNumber', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const checkEmail = (request) => {
    return new Promise((res, rej) => {
        axios.post('check/email', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const verifyOTP = (request) => {
    return new Promise((res, rej) => {
        axios.post('verify/otp', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const makeIdvalidation = (request) => {
    return new Promise((res, rej) => {
        axios.post('/idValidationWithPhone', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const registerUser = (request) => {
    return new Promise((res, rej) => {
        axios.post('/register', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const connectFinicity = (request) => {
    return new Promise((res, rej) => {
        axios.post('/generateConnectURL', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const uploadIDCardImage = (request) => {
    var config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return new Promise((res, rej) => {
        axios.post('/idValidationManual', request, config)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const redirectToValidStep = (request) => {
    return new Promise((res, rej) => {
        axios.post('/redirect', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const getAssessment = (request) => {
    return new Promise((res, rej) => {
        axios.post('/identityAssessment', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const enterPIN = (request) => {
    return new Promise((res, rej) => {
        axios.post('/enterPin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const sendLink = (request) => {
    return new Promise((res, rej) => {
        axios.post('/sendLink', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const completeRegistration = (request) => {
    return new Promise((res, rej) => {
        axios.post('completeregistration', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const updateLiteConsumerDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('/update-consumer-details-after-lite-registration', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const updateConsumerLiteToStandard = () => {
    return new Promise((res, rej) => {
        axios.post('/update-consumer-lite-to-standard')
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
const validateManualBankAccount = (request) => {
    return new Promise((res, rej) => {
        axios.post('/validatebankdetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    checkPhonenumber,
    verifyPhonenumber,
    checkEmail,
    verifyOTP,
    makeIdvalidation,
    registerUser,
    connectFinicity,
    uploadIDCardImage,
    redirectToValidStep,
    getAssessment,
    enterPIN,
    sendLink,
    loginUser,
    refreshBalance,
    completeRegistration,
    updateLiteConsumerDetails,
    updateConsumerLiteToStandard,
    validateManualBankAccount
};