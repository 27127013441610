<template>
    <div class="slider-background-layer">
        <div class="custom-range-slider" ref="slider">
            <div @click="drag" class="custom-range-slider-track" ref="track">
                <div class="gSide-stick1"></div>
                <div class="gSide-stick2"></div>
                <div class="gSide-stick3"></div>
                <div class="gSide-stick4"></div>
                <div class="mSide" :style="merchantTrackStyle">
                </div>
                <div :class="gPoint > 0 && consumer_type != 'lite' ? 'gSide' : 'gSide cp-slider-disabled'" :style="genericTrackStyle">
                </div>
            </div>
            <div class="custom-range-slider-thumb" ref="thumb">
                <div class="custom-range-slider-thumb-inner">
                    <svg width="18" viewBox="0 0 66 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.8638 33.5705L1.70234 20.0582C1.29742 19.7726 0.966708 19.3924 0.738352 18.9499C0.509996 18.5073 0.390747 18.0156 0.390747 17.5164C0.390747 17.0173 0.509996 16.5255 0.738352 16.083C0.966708 15.6404 1.29742 15.2602 1.70234 14.9746L20.8638 1.44343C21.3241 1.11795 21.864 0.926567 22.4245 0.890165C22.9851 0.853763 23.5448 0.973744 24.0425 1.23701C24.5402 1.50028 24.9567 1.89671 25.2467 2.38303C25.5367 2.86934 25.689 3.42686 25.6869 3.9947V31.0381C25.6856 31.6044 25.5308 32.1594 25.2395 32.643C24.9481 33.1266 24.5312 33.5203 24.0342 33.7813C23.5371 34.0423 22.9788 34.1606 22.4199 34.1235C21.8609 34.0863 21.3228 33.8951 20.8638 33.5705Z" fill="url(#paint0_linear_10943_6)"/>
                    <path d="M45.37 1.43045L64.5606 14.9652C64.9621 15.2536 65.2894 15.6348 65.5153 16.0771C65.7412 16.5195 65.8591 17.01 65.8591 17.5078C65.8591 18.0055 65.7412 18.496 65.5153 18.9384C65.2894 19.3807 64.9621 19.7619 64.5606 20.0503L45.37 33.5662C44.9124 33.8926 44.3748 34.0853 43.816 34.1232C43.2572 34.1611 42.6988 34.0428 42.2022 33.7811C41.7056 33.5194 41.2899 33.1245 41.0007 32.6397C40.7116 32.155 40.5601 31.5991 40.563 31.0331V3.9824C40.5633 3.41747 40.7163 2.86336 41.0055 2.37993C41.2947 1.89649 41.7091 1.50212 42.204 1.2394C42.6989 0.976691 43.2553 0.855631 43.8133 0.889301C44.3713 0.922971 44.9096 1.11009 45.37 1.43045Z" fill="url(#paint1_linear_10943_6)"/>
                    <defs>
                    <linearGradient id="paint0_linear_10943_6" x1="13.0388" y1="0.883789" x2="13.0388" y2="34.1302" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0064B6"/>
                    <stop offset="1" stop-color="#34A4FF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_10943_6" x1="53.211" y1="0.883789" x2="53.211" y2="34.1302" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0064B6"/>
                    <stop offset="1" stop-color="#34A4FF"/>
                    </linearGradient>
                    </defs>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props:{
        value: Number,
        totalPoints: {
            type: [Number, String],
            default: 0
        },
        merchantPoints: {
            type: [Number, String],
            default: 0
        },
        genericPoints: {
            type: [Number, String],
            default: 0
        },
        minimumRedeemPoints: {
            type: [Number, String],
            default: 0
        },
        isMerchantAvailable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            min: 0, // current value
            actualValue: 0,

            tsp: 0,
            mp: 0,
            gp: 0,
            trackWidth: 0,
            selected_points: 0,
            merchantPointsUsed: false,
            forceDrag: false,
            consumer_type: localStorage.getItem("consumer_login_response")
                ? JSON.parse(localStorage.getItem("consumer_login_response")).consumer_type
                : null,
        }
    },
    computed: {
        merchantTrackStyle(){
            var width = '33' ;
            var borderradius = '50px 0 0 50px';
            return this.isMerchantAvailable ? 'width: '+ width +'%; border-radius: '+ borderradius +'; border-left:1px solid #000000; border-top:1px solid #000000' : 'width: 0%;'
        },
        genericTrackStyle(){
            var width = '67';
            return this.isMerchantAvailable ? 'width: '+ width +'%; border-radius: 0 50px 50px 0;' : 'width: 100%; border-radius: 50px;'
        },
        max(){
            return this.totalPoints
        },
        step(){
            return this.minimumRedeemPoints
        },
        mPoint(){
            return parseInt(this.merchantPoints/100) * 100
        },
        gPoint(){
            return parseInt(this.genericPoints/100) * 100
        }
    },  
    mounted(){
        var self = this;
        this.setPosition();
        this.$refs.thumb.addEventListener('mousedown', this.startDrag);
        this.$refs.thumb.addEventListener('touchstart', this.startDrag);
        
    },
    watch:{
        value: function () {
            this.setPosition();
        },
        forceDrag: function () {
            var self = this;
            setTimeout(function() {                
                if (self.forceDrag) {
                    self.endForceDrag();
                }
            }, 1000);
        }
    },  
    methods: {
        startDrag(event) {
            event.stopPropagation();
            event.preventDefault();
            // Update value and position of thumb
            document.addEventListener('mousemove', this.drag);
            document.addEventListener('touchmove', this.drag);
            document.addEventListener('mouseup', this.endDrag);
            document.addEventListener('touchend', this.endDrag);
            document.addEventListener('touchcancel', this.endDrag);
        },
        drag(event) {
            const position = this.getPosition(event);
            this.forceDrag = true;
            this.actualValue = Math.round( (position / this.$refs.track.offsetWidth) * (this.max - this.min) / this.step ) * this.step;

            const overAllPoistion = (this.actualValue / this.totalPoints) * 100;
            console.log('this.isMerchantAvailable ', this.isMerchantAvailable);
            if(this.isMerchantAvailable){
                const merchantPercentage = Math.round(
                    Math.min(overAllPoistion / 33, 1) * 100
                );
                const mCalculatePoint = parseInt(
                    (merchantPercentage * this.mPoint) / 100
                );
                const genericPercentage = Math.round(
                    Math.max((overAllPoistion - 33) / 67, 0) * 100
                );
                const gCalculatePoint = parseInt(
                    (genericPercentage * this.gPoint) / 100
                );

                this.mp = parseInt(mCalculatePoint / 100) * 100
                this.gp = parseInt(gCalculatePoint / 100) * 100

                this.tsp = parseInt(mCalculatePoint / 100) * 100 + parseInt(gCalculatePoint / 100) * 100;

                if (this.tsp >= this.mPoint) {
                    if(!this.merchantPointsUsed){
                        this.merchantPointsUsed = true;
                        this.tsp = this.mPoint;
                        // Merchant points fully used, reset and stop dragging
                        this.value = (33 / 100) * this.totalPoints; // Reset to 25%
                        this.setPosition();
                        this.endDrag();
                        return; // Require the user to drag again
                    }
                } else {
                    this.merchantPointsUsed = false;
                }
                this.$emit('input', parseInt(mCalculatePoint / 100) * 100 + parseInt(gCalculatePoint / 100) * 100);

            }else{
                const genericPercentage = Math.round(
                    Math.min(overAllPoistion / 100, 1) * 100
                );
                const gCalculatePoint = parseInt(
                    (genericPercentage * this.gPoint) / 100
                );

                this.$emit('input', parseInt(gCalculatePoint / 100) * 100);

                this.gp = parseInt(gCalculatePoint / 100) * 100

                this.tsp = parseInt(gCalculatePoint / 100) * 100;
            }
            this.setPosition();
        },
        endDrag(event) {
            
            this.$emit('change', this.tsp);
            this.selected_points = this.tsp;
            document.removeEventListener('mousemove', this.drag);
            document.removeEventListener('touchmove', this.drag);
            document.removeEventListener('mouseup', this.endDrag);
            document.removeEventListener('touchend', this.endDrag);
            document.addEventListener('touchcancel', this.endDrag);
        },
        endForceDrag(event) {
            this.$emit('change', this.tsp);
            this.selected_points = this.tsp;
            this.forceDrag = false;
        },
        getPosition(event){
            const isTouch = event.type.startsWith('touch');
            const clientX = isTouch ? event.touches[0].clientX : event.clientX;
            const trackRect = this.$refs.track.getBoundingClientRect();
            const trackLeft = trackRect.left + window.pageXOffset;
            this.trackWidth = trackRect.width;
            const position = Math.max(0, Math.min(this.trackWidth, clientX - trackLeft));
            return position;
        },
        setPosition(event) {
            // Create a custom touch event
            if(this.trackWidth == 0) {
                const touchStartEvent = new TouchEvent('touchstart', {
                bubbles: true,
                cancelable: true,
                touches: [
                    new Touch({
                    identifier: Date.now(),
                    target: this.$refs.thumb,
                    clientX: 0, // Add the necessary coordinates
                    clientY: 0,
                    screenX: 0,
                    screenY: 0,
                    pageX: 0,
                    pageY: 0,
                    radiusX: 0,
                    radiusY: 0,
                    rotationAngle: 0,
                    force: 1
                    })
                ],
                targetTouches: [],
                changedTouches: [],
                shiftKey: false
                });

                // Dispatch the event on the thumb element
                this.$refs.thumb.dispatchEvent(touchStartEvent);
                this.getPosition(touchStartEvent);
            }
            if(this.isMerchantAvailable){
                if ((this.value - this.min) > (this.merchantPoints - this.min)) {
                    var position = ((this.trackWidth * 33) / 100) + (((this.value - (this.merchantPoints + this.min)) / (this.gPoint - this.min)) * ((this.trackWidth * 67) / 100));
                } else {
                    var position = (((this.value - this.min) / (this.mPoint - this.min)) * ((this.trackWidth * 33) / 100));
                }
                
            } else {
                var position = ((this.value - this.min) / (this.max - this.min)) * this.trackWidth;
            }
            
            this.$refs.thumb.style.left = `${position}px`;
        }
    }
}
</script>

<style>
    .slider-background-layer{
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: #007ee5;
        border: 1px solid #000;
        border-radius: 50px;
    }
    .custom-range-slider {
        position: relative;
        width: 100%;
        /* height: 30px; { Don't remove commented code } */
        border-radius: 5px;
        display: flex;
        align-items: center;
    }

    .custom-range-slider-track {
        width: 100%;
        height: 20px;
        border-radius: 50px;
        display: flex;
        /* border: 1px solid #000;  { Don't remove commented code } */
    }
    .mSide{
        position: relative;
        background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%);
        background-color: #ebb700;
        height: 100%;
    }
    .gSide{
        position: relative;
        /* background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%); 
        background-color: #007ee5; { Don't remove commented code } */
        height: 100%;
    }
    .gSide-stick1{
        width: 2px;
        height: 8px;
        background-color: black;
        left: 15%;
        position: absolute;
        bottom: 0;
        z-index: 10;
    }
    .gSide-stick2{
        width: 2px;
        height: 8px;
        background-color: black;
        left: 37%;
        position: absolute;
        bottom: 0;
        z-index: 10;
    }
    .gSide-stick3{
        width: 2px;
        height: 8px;
        background-color: black;
        left: 62%;
        position: absolute;
        bottom: 0;
        z-index: 10;
    }
    .gSide-stick4{
        width: 2px;
        height: 8px;
        background-color: black;
        left: 84%;
        position: absolute;
        bottom: 0;
        z-index: 10;
    }

    .custom-range-slider-thumb {
        position: absolute;
        width: 45px;
        height: 30px;
        background-color: #007ee5;
        border: 1px solid #000;
        border-radius: 50px;
        cursor: grab;
        top: 50%;
        left: 0%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 2px;
        z-index: 11;
    }
    .custom-range-slider-thumb-inner{
        background: linear-gradient(180deg, #0D0D0E 0%, #303030 90.98%);
        width: 100%;
        height: 100%;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cp-slider-disabled{
    -webkit-appearance: none;
    width: 100%;
    height: 20px;
    background: linear-gradient(180.01deg, rgba(0, 0, 0, 0.29) 0.01%, rgba(0, 126, 229, 0) 96.86%)!important;
    background-color: #EFEFEF!important;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    border: 1px solid #000;
    border-radius: 50px;
    cursor: no-drop;
    }
    @media only screen and ( min-width:280px) and ( max-width:700px) {
        .custom-range-slider{
            width: 100%;
        }
    }
    @media only screen and ( min-width:320px) and ( max-width:700px) {
        .custom-range-slider{
            width: 100%;
        }
    }
    @media only screen and ( min-width:376px) and ( max-width:800px) {
        .custom-range-slider{
            width: 100%;
        }
    }
</style>